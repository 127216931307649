import { memo, useEffect, useRef, useState } from "react"

import styled from "styled-components"
import { animated } from "@react-spring/web"
import { config, useSpring } from "@react-spring/core"

function AnimatedBorderButton({
  className,
  children,
  onClick,
  strokeColor,
  strokeWidth,
  active,
  size = "small",
  style
}) {
  //TODO aniamte out same time as new active aniamte in
  const [internalActive, set] = useState(false)

  useEffect(() => {
    set(active)
  }, [active])

  const handleClick = () => {
    set(true)
    onClick()
  }

  const { x } = useSpring({
    x: internalActive ? 1 : 0,

    config: internalActive
      ? config.stuff
      : { mass: 1, tension: 210, friction: 20, clamp: true }
  })

  const length = 478.0784912109375

  const path = useRef()

  return (
    <>
      <Button style={style} className={className} onClick={handleClick}>
        <animated.svg
          style={{ transform: "translate(0, -1%)" }}
          width="128"
          height="40"
          fill="none"
          viewBox="0 0 205.7 60"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          strokeDasharray={length}
          strokeDashoffset={x.to(x => (1 - x) * length)}>
          <path
            ref={path}
            stroke={strokeColor || "#1E1E1C"}
            strokeWidth={strokeWidth || "2px"}
            d="M137,0c-0.6,0.8-1.4,1.7-2.4,2.8c-0.9,1-1.8,1.9-3.1,3c-1.6,1.4-3,2.2-4.5,3.1c-0.4,0.3-1.5,0.9-2.5,1.4 c-2.9,1.5-5.4,2.1-9.7,3.1c-7.1,1.7-6.8,0.7-8,1c-0.1,0-0.5,0.1-0.7,0c-0.1-0.2,0-0.5,0.1-0.6c0.2-0.4,0.6-0.5,0.7-0.6 c1.2-0.5,1.8-1.2,3.2-1.6c3.4-1,10-0.5,10-0.5c3.1,0.3,6.2,0.1,9.3,0.3c9.5,0.4,8.8,0.1,18.5,0.6c6.2,0.3,10,0.5,15.5,0.9 c0,0,5.5,0.4,12.5,1.3c1.1,0.1,3,0.4,5.5,1c1.7,0.4,3.4,0.8,5.6,1.7c1.4,0.6,3.3,1.4,5.5,3c0.4,0.3,1.2,0.9,2.2,1.8 c2.3,2.2,3.6,4.3,6,8.5c1.5,2.7,2.1,3.9,2.4,4.8c1.1,2.6,1.2,4,1.3,4.6c0.2,1.9-0.1,3.3-0.1,3.6c-0.2,0.8-0.5,2.2-1.5,3.8 c-0.4,0.6-1.3,2-3.6,3.7c-1,0.8-3,2-6.8,3.4c-3.4,1.2-6,1.7-9.3,2.3c-3.9,0.7-6.9,1.1-7.9,1.2c-7.5,0.9-13.7,0.9-17.4,0.9 c-12.9,0.1-19.7-0.2-19.7-0.2c-20.1-0.9-43.4-0.9-57.4-0.8c-13.9,0-27.8-0.5-41.6-0.7c0,0-4.5,0-11.6-0.8c-9.8-1-13.2-2.3-15-3.1 c-3.5-1.5-6.3-2.7-8.6-5.7C-1,41.2,1,33.1,1.5,31.1c0.5-2.1,2.1-7,6.5-11c2.5-2.3,5.1-3.5,6.9-4.1c9.8-3.8,21.2-3.5,29-3.4 c19.4,0.3,14.5-0.4,30.1,0c17.4,0.5,18.5,1.3,21.1,0.1"
          />
        </animated.svg>
        <Item size={size}>{children}</Item>
      </Button>
    </>
  )
}
export default memo(AnimatedBorderButton)

const Button = styled(animated.div)`
  pointer-events: auto;
  display: flex;
  align-items: center;
  position: relative;
  font-family: GT-Sectra-Light;
  cursor: pointer;
  font-size: 1.2rem;
  user-select: none;
`

const Item = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${props => (props.size === "small" ? "1em" : "1em")};
  width: max-content;
`
