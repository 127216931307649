import { animated, useTransition } from "@react-spring/web"
import styled from "styled-components"
import { useMemo, useEffect, useLayoutEffect, useState } from "react"
import { useStore } from "store"

import useMeasure from "react-use-measure"
import RoomCard from "./RoomCard"

export default function AnimatedCardList() {
  const apartments = useStore(state => state.protoFaves)
  const setViewTitle = useStore(state => state.setViewTitle)

  // const [ratio, setRatio] = useState(0)

  useEffect(() => {
    setViewTitle(true)
    return () => setViewTitle(true)
  }, [setViewTitle])

  const columns = 2
  const rowsToPage = 4

  const [ref, { width, height }] = useMeasure()

  const [gridItems] = useMemo(() => {
    let gridItems = apartments.map((child, i) => {
      const x = `${getPosition(i, rowsToPage, columns, width).col}px`
      const y = `${getPosition(i, rowsToPage, columns, height).row}px`
      // const y = "20px"

      return {
        ...child,
        x,
        y,
        width: width / columns,
        height: height / rowsToPage,
        // height: (width / columns) * ratio - margin * ratio,
        index: i
      }
    })
    return [gridItems]
  }, [columns, width, apartments])

  const transitions = useTransition(gridItems, {
    from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    update: ({ x, y, width, height }) => ({ x, y, width, height }),
    leave: { opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 30
  })

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        width: "100%",
        height: "100%"
      }}>
      {transitions((style, item) => (
        <CardContainer
          key={item.index}
          style={{
            ...style
          }}>
          <RoomCard apartment={item} />
        </CardContainer>
      ))}
    </div>
  )
}

const getPosition = (index, rowNum, colNum, distance) => {
  const col = ((index % colNum) * distance) / colNum
  const row = (Math.floor(index / colNum) * distance) / rowNum
  return {
    row,
    col
  }
}

export function AnimatedMiniList({
  compWidth = 50
  // compWidth = 150
  // height = "30"
  // width = "100%"
}) {
  const apartments = useStore(state => state.protoFaves)

  const columns = 1
  const margin = 10

  const [gridItems] = useMemo(() => {
    let gridItems = apartments.map((child, i) => {
      const x = `${i * (compWidth + margin)}px`
      const y = "0px"

      return {
        ...child,
        x,
        y,
        width: compWidth,
        index: i
      }
    })
    return [gridItems]
  }, [columns, apartments])

  const transitions = useTransition(gridItems, {
    from: ({ x, y, width }) => ({ x, y, width, opacity: 0 }),
    enter: ({ x, y, width }) => ({ x, y, width, opacity: 1 }),
    update: ({ x, y, width }) => ({ x, y, width }),
    leave: { opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 30
  })

  return (
    // <MiniCardsContainer ref={ref}>
    <>
      {transitions((style, item) => (
        <MiniCardContainer
          key={item.index}
          style={{
            ...style
          }}>
          <RoomCard apartment={item} isMini={true} />
        </MiniCardContainer>
      ))}
    </>
  )
}

const MiniCardContainer = styled(animated.div)`
  position: absolute;
  min-height: 8.5vh;
  width: 4vh;
  /* overflow: visible; */
  pointer-events: auto;
  will-change: transform, width, height, opacity;
`
const CardContainer = styled(animated.div)`
  box-sizing: border-box;
  padding: 2rem;
  /* padding: 1.5rem 4rem; */
  position: absolute;
  overflow: visible;
  pointer-events: auto;
  will-change: transform, width, height, opacity;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
