import { useGLTF, useTexture } from "@react-three/drei"
import { useRef, useState } from "react"
import { useStore } from "store"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import * as THREE from "three"

import { useTransition, a } from "@react-spring/three"

import { PUBLIC_ASSET_PATH } from "dataset/common"
import { MathUtils } from "three"
// import { useThree } from "@react-three/fiber"

function ApartmentModelsNew() {
  const ref = useRef()
  const activeApartment = useStore(s => s.activeApartment)
  const transition = useTransition(activeApartment, {
    from: {
      position: [0, -1000, 100],
      rotation: [0, Math.PI / 2, 0],
      scale: [0, 0, 0],
      opacity: 0
    },
    enter: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [1, 1, 1],
      opacity: 1
    },
    leave: {
      position: [0, -1000, -100],
      rotation: [0, -Math.PI / 2, 0],
      scale: [0, 0, 0],
      opacity: 0
    },
    config: () => n => n === "opacity" && { friction: 60 }
  })

  //   //TODO Positon vector changes accodring to camerea orientation
  return transition(({ opacity, ...props }, activeApartment) => (
    <a.group ref={ref} {...props}>
      {activeApartment && (
        <mesh>
          <LoadAptModel opacity={opacity} apartment={activeApartment} />
        </mesh>
      )}
    </a.group>
  ))
}

function LoadAptModel({ apartment, opacity }) {
  const [zoomed, setZoom] = useState()

  const type = apartment[APARTMENT_FIELDS.type]

  const { nodes } = useGLTF(`${PUBLIC_ASSET_PATH}/models/allapts/${type}.glb`)
  const map = useTexture(`${PUBLIC_ASSET_PATH}/textures/${type}_d.jpg`)
  const setIsCube = useStore(s => s.setIsCube)

  const nodesArr = Object.values(nodes)

  nodesArr.map(v =>
    v.geometry.attributes.position.count === 24
      ? setIsCube(true)
      : setIsCube(false)
  )
  // nodesArr.map(v => console.log(v.geometry.attributes.position.count))

  map.flipY = false
  map.encoding = THREE.sRGBEncoding

  const BAKED = new THREE.MeshBasicMaterial({
    map: map
  })

  const flip = parseInt(apartment.flip)

  // console.log(MathUtils.degToRad(parseInt(apartment.offset)))

  return (
    <>
      {nodesArr.map(n => (
        <a.mesh
          rotation-y={MathUtils.degToRad(parseInt(apartment.offset))}
          onPointerOver={() => console.log("over")}
          onClick={() => console.log("click")}
          scale={zoomed ? 1.7 : 1}
          scale-x={flip === 1 ? -1 : 1}
          key={n.name}
          material={BAKED}
          material-opacity={opacity}
          geometry={n.geometry}></a.mesh>
      ))}
    </>
  )
}

export default ApartmentModelsNew
