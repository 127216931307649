import IconButton from "components/Buttons/IconButton"
import styled from "styled-components"
import MenuIcon from "assets/icons/apartment-finder-icon-new.svg"
// import CloseIcon from "assets/icons/close.svg"
import { useStore } from "store"
// import { useTransition, animated as a } from "@react-spring/web"

export default function ApartmentFinderBtn({ isNotVisible }) {
  const setFinderVisible = useStore(state => state.setFinderVisible)
  const filterVisible = useStore(state => state.filterVisible)
  const setFilterVisible = useStore(state => state.setFilterVisible)

  const handleClick = () => {
    setFinderVisible(true)
    filterVisible && setFilterVisible(false)
  }

  // const transitions = useTransition(true, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },

  //   delay: 0
  // })

  return (
    <Button
      style={{
        display: "block"
      }}
      icon={MenuIcon}
      onClick={handleClick}
    />
  )
}

const Button = styled(IconButton)`
  position: absolute;
  right: 0.5em;
  bottom: 0.7em;
  pointer-events: all;
  width: 4.2vh;
  height: 4.2vh;
  padding: 0;
  transition: all 0.2s ease-out;
  &:hover {
    opacity: 0.6;
  }
`
