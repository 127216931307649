export const APARTMENT_FIELDS = {
  beds: "Size (Bed/Person)",
  type: "Flat Type",
  totalArea: "Area GIA (m2)",
  unitCode: "unit #",
  aspect: "Aspect",
  bath: "Bath",
  level: "Level",
  area: "SqFt",
  number: "Apt No",
  building: "Building Name",
  firstAddress: "Address",
  status: "Availability",
  price: "Price",
  fullValue: "Full Value",
  depositRequired: "Deposit Required",
  shareValue: "Share Value",
  monthlyCost: "Monthly Cost",
  monthlyMortgate: "Monthly Mortgage",
  monthlyRent: "Monthly Rent",
  monthlyCharge: "Monthly Service Charge",
  minHI: "Minimum Household Income",
  maxHI: "Maximum Household Income"
}
