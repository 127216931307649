import styled from "styled-components"

import { useStore } from "store"
import ApartmentCard from "components/ApartmentCard"
import { ApartCardList, Footer, Gallery, Header, Illustration } from "../Share"

import TopBrandIcon from "assets/images/share/top-brand.svg"
import BrandsIcon from "assets/images/share/brands.svg"
import BrandIcon from "assets/images/share/brand.svg"
import GalleryImage from "assets/images/share/gallery.svg"
import IllustrationImage from "assets/images/share/illustration.svg"

export default function PrintShare() {
  const favourites = useStore(state => state.favourites)

  return (
    <Holder id="print-share-page">
      <Header>
        <h1>your favorites</h1>
        <img src={TopBrandIcon} alt="" />
      </Header>
      <ApartCardList>
        {favourites?.map((apt, index) => (
          <ApartmentCard key={index} apartment={apt} isShare={false}/>
        ))}
      </ApartCardList>
      <Gallery src={GalleryImage} alt="" />
      <Illustration src={IllustrationImage} alt="" />
      <Footer>
        <div>
          <a href="/">PARK151.COM</a>
        </div>
        <div>
          <img src={BrandIcon} alt="" />
        </div>
        <div>
          <img src={BrandsIcon} alt="" />
        </div>
      </Footer>
    </Holder>
  )
}

const Holder = styled.div`
  position: fixed;
  top: 9999px;
  left: 0;
  z-index: 1;
  width: 1400px;
  background-color: white;
`
