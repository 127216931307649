const degree = {
  top: "-90deg",
  right: "0",
  bottom: "90deg",
  left: "180deg"
}

const ArrowIcon = ({ color = "#000000", direction = degree.right }) => {
  return (
    <svg
      style={{
        width: "100%",
        transform: `rotateZ(${degree[direction]})`
      }}
      width="32"
      height="29"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_795_205)">
        <path
          d="M13.0726 0L26.1207 13.048H0V15.947H26.1207L13.0726 29H17.1628L31.6628 14.5L17.1628 0H13.0726Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_795_205">
          <rect width="31.6628" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowIcon
