import { useRef, useState, useEffect } from "react"
import CloseIcon from "assets/icons/close.svg"
import { useStore } from "store"
import styled from "styled-components"
import { animated, useTransition } from "@react-spring/web"
import { PUBLIC_ASSET_PATH } from "dataset/common"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import Park151Logo from "assets/svg/Park151MidnightLogo.svg"
import CXLogo from "assets/images/about/cxlogo.png"
// import CX from "assets/images/apartment/logos/cx.png"

function FloorplanDialogue() {
  const setFloorplanDialogue = useStore(s => s.setFloorplanDialogue)
  const floorplanDialogue = useStore(s => s.floorplanDialogue)
  const activeApartment = useStore(s => s.activeApartment)
  const isCube = useStore(s => s.isCube)
  const setIsCube = useStore(s => s.setIsCube)

  const floorPlanImageRef = useRef(null)
  const [imageWidth, setImageWidth] = useState(0)

  const transition = useTransition(floorplanDialogue || isCube, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    return () => setIsCube(false)
  }, [])

  useEffect(() => {
    var img = floorPlanImageRef

    // Fit image to container if floorplan too wide
    if (img.current) {
      img.current.onload = function () {
        const width = img.current?.width

        console.log("WIDTH", width)
        setImageWidth(width)
        if (width > 700 && width < 899) img.current.style = "max-height: 25vw"
        if (width > 899) img.current.style = "max-height: 25vw"
        if (width >= 596 && width <= 700) img.current.style = "max-height: 9em"
      }
    }
  })

  if (activeApartment) {
    return (
      <>
        {transition(
          (style, item) =>
            item && (
              <FloorplanDialogueHolder style={style}>
                <CloseF onClick={() => setFloorplanDialogue(false)} />
                <Header>
                  <Logo src={Park151Logo} alt="" />
                  <Address>
                    <img src={CXLogo} alt="" />
                    <div>
                      151 N 1ST ST, Cambridge, MA <br />
                      park151.com {imageWidth}
                    </div>
                  </Address>
                </Header>
                <Content>
                  <div className="images">
                    <div className="unitIndicatorWrapper">
                      <img
                        className="unitIndicator"
                        src={`${PUBLIC_ASSET_PATH}/images/apartment/unitIndicatorShape.png`}
                        alt=""
                      />
                      <span>
                        UNIT {activeApartment[APARTMENT_FIELDS.unitCode]}
                      </span>
                    </div>
                    <img
                      ref={floorPlanImageRef}
                      className="floorPlan"
                      src={`${PUBLIC_ASSET_PATH}/svgfloorplans/${
                        activeApartment[APARTMENT_FIELDS.type]
                      }.png`}
                    />
                    <img
                      className="floorPlate"
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/plates/${
                        activeApartment[APARTMENT_FIELDS.type]
                      }.jpg`}
                    />
                  </div>
                </Content>
                <Footer>
                  <div className="text">
                    Renderings are an artist’s conception and are intended only
                    as a general reference. Features, materials, finishes and
                    layout of subject unit may be different than shown. <br />
                    *In select units only.
                  </div>
                  <div className="logos">
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/lcor.png`}
                      alt=""
                    />
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/divcowest.png`}
                      alt=""
                    />
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/equalhousing.png`}
                      alt=""
                    />
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/accessible.png`}
                      alt=""
                    />
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/cx.png`}
                      alt=""
                    />
                    <img
                      src={`${PUBLIC_ASSET_PATH}/images/apartment/logos/last.png`}
                      alt=""
                    />
                  </div>
                </Footer>
              </FloorplanDialogueHolder>
            )
        )}
      </>
    )
  } else return null
}

function CloseF({ onClick }) {
  const setIsCube = useStore(s => s.setIsCube)
  useEffect(() => {
    return () => setIsCube(false)
  }, [])

  return <Close onClick={onClick} />
}
export default FloorplanDialogue

const Close = styled.div`
  cursor: pointer;
  z-index: 20000;
  position: absolute;
  top: 12vh;
  right: 0;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-size: contain;
  height: 4.5vh;
  width: 4.5vh;
  margin: 1.5rem;
  transition: all 0.2s ease-out;
  &:hover {
    opacity: 0.6;
  }
`

const FloorplanDialogueHolder = styled(animated.div)`
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fafbf5;
`

const Header = styled.div`
  display: flex;
  background: #f87144;
  height: 12vh;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em;
  border-bottom: 2px solid;
`

const Footer = styled.div`
  display: flex;
  height: 12vh;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-top: 2px solid;
  position: absolute;
  bottom: 0;
  width: 100%;

  & .text {
    letter-spacing: 2px;
    font-size: 0.3em;
    width: 50%;
  }

  & .logos {
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
    align-items: center;
    width: 200px;
    flex-wrap: wrap;
  }
`

const Content = styled.div`
  height: calc(100% - 24vh);

  & .images {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & img.floorPlan {
    max-height: 12em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in;
  }

  & img.floorPlate {
    height: 2.5em;
    margin: 0.5em;
    position: absolute;
    right: 0;
    bottom: 12vh;
  }

  & img.unitIndicator {
    height: 1.3em;
    position: absolute;
  }

  & .unitIndicatorWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.2em;
    margin-top: 1em;
    position: absolute;
    top: 12vh;
  }

  & span {
    font-family: GT-Sectra-Light;
    letter-spacing: 1px;
    font-size: 0.7em;
    position: relative;
    left: 50%;
    width: 100%;
    top: 50%;
    transform: translate(-50%, 0%);
  }

  @media screen and (min-width: 1200px) {
    img.floorPlan {
      max-height: 19em;
    }
  }
`

const Logo = styled.img`
  max-height: 2em;
`
const Address = styled.div`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.4em;
  font-weight: 400;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;

  & img {
    height: 2.5em;
  }
`
