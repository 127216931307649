export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function getMinValue(arr, criteria) {
  let min
  for (let i = 0; i < arr.length; i++) {
    if (min == null || parseInt(arr[i][criteria]) < parseInt(min[criteria])) {
      min = arr[i]
    }
  }
  return Number(min[criteria])
}

export function getMaxValue(arr, criteria) {
  let max
  for (let i = 0; i < arr.length; i++) {
    if (max == null || parseInt(arr[i][criteria]) > parseInt(max[criteria])) {
      max = arr[i]
    }
  }
  return Number(max[criteria])
}
