import React, { Suspense, useEffect, useRef } from "react"
import styled from "styled-components"
import { Canvas, useFrame } from "@react-three/fiber"
import { Html, OrbitControls, Text } from "@react-three/drei"
import { DoubleSide, MathUtils, Vector3 } from "three"
import { range } from "lodash-es"

import { useStore } from "store"

// import Loader from "components/Loader"
const BuildingModel = React.lazy(() => import("./loaders/BuildingModelLoader"))

const font = `${process.env.PUBLIC_URL}/fonts/GT-Sectra-Light.ttf`

export default function BuildingScene() {
  const buildingSceneLoaded = useStore(s => s.buildingSceneLoaded)

  return (
    <Canvas
      gl={{
        antialias: true
      }}
      dpr={2}
      camera={{
        fov: 40,
        near: 0.1,
        far: 10000,
        position: [1250, 2225, 1210]
      }}>
      {buildingSceneLoaded && (
        <>
          <Suspense fallback={<ThreeSpinner />}>
            <BuildingModel />
            <StaticCompass />
            <CameraControls />
          </Suspense>
        </>
      )}
    </Canvas>
  )
}

function ThreeSpinner() {
  return (
    <Html center>
      <h1
        style={{
          color: "white",
          fontFamily: "Circular-Std",
          fontSize: "20px",
          letterSpacing: "5px",
          opacity: 0.3
        }}
        //
      >
        Loading
      </h1>
    </Html>
  )
}

function StaticCompass() {
  const angles = range(0, 360, 11.25)
  const markerAngles = range(0, 360, 90)

  const offset = 2.3

  const markers = ["N", "W", "S", "E"]

  const radius = 140
  const thickness = 9
  const fontSize = 34

  return (
    <>
      <group position={[0, -100, 0]}>
        {angles.map(a => (
          <group key={a} rotation-y={MathUtils.degToRad(a) + offset}>
            <mesh
              position={[
                a % 45 === 0
                  ? //  && a % 90 !== 0
                    radius + thickness
                  : radius,
                0,
                0
              ]}
              rotation-x={Math.PI / 2}>
              <planeBufferGeometry
                args={[
                  a % 45 === 0
                    ? // && a % 90 !== 0
                      thickness * 2
                    : thickness,
                  1.5,
                  10,
                  10
                ]}
              />
              <meshBasicMaterial
                // color="#ffffff"
                color="#555a54"
                transparent={true}
                opacity={
                  a % 45 === 0
                    ? 0.5 //  && a % 90 !== 0
                    : 0.2
                }
                side={DoubleSide}
              />
            </mesh>
          </group>
        ))}
        {markerAngles.map((a, i) => (
          <group
            key={a}
            //
            rotation-y={MathUtils.degToRad(a) + offset}
            //
          >
            <group
              position={[radius + thickness + fontSize - 3, 0, 0]}
              rotation-x={-Math.PI / 2}
              rotation-z={Math.PI / 2}
              //
            >
              <Text
                font={font}
                characters="abcdefghijklmnopqrstuvwxyz0123456789!"
                color="#2f2e2e"
                fontSize={fontSize}
                anchorX="center"
                anchorY="middle">
                {markers[i]}
              </Text>
            </group>
          </group>
        ))}
      </group>
    </>
  )
}

function CameraControls() {
  // TODO Re-renders every frame
  const controls = useRef()

  const o = new Vector3(0, -20, 0)
  const p = new Vector3()

  const activeApartment = useStore(state => state.activeApartment)
  const cameraRotation = useStore(state => state.cameraRotation)

  useFrame(({ camera, clock }) => {
    if (activeApartment) {
      p.x = Math.sin(cameraRotation + 3) * 600
      p.z = Math.cos(cameraRotation + 3) * 600
      p.y = 300

      camera.position.lerp(p, 0.15)
    }
    camera.lookAt(o)
  })

  useEffect(() => {
    if (activeApartment) {
      controls.current.enabled = false
    } else {
      controls.current.enabled = true
    }
  })

  return (
    <OrbitControls
      ref={controls}
      enablePan={false}
      maxPolarAngle={1.25}
      minPolarAngle={1.25}
      minDistance={400}
      maxDistance={600}
    />
  )
}

// const LoaderHolder = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   backdrop-filter: blur(6px);
//   background-color: #2d2d2d99;
// `
// {/* <mesh rotation-x={Math.PI / 2}>
// <ringGeometry args={[radius, radius + 10, 64]} />
// <meshBasicMaterial
// color="#777d76"
// transparent={true}
// opacity={0.4}
// side={DoubleSide}
// />
// </mesh>  */}
