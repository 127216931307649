const theme = {
  colors: {
    darkBeige: "#B59E8B",
    lightBeige: "#CAAB97",
    finderTextColor: "#030201",
    finderBgColor: "#BBCCB6",
    lightPale: "#F9F1EC",
    midGrey: "#676767",
    lightGrey: "#E9E9E9"
  },
  spacing: { navHeight: "5%", pageHeight: "95%" }
}

export default theme
