import { useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useStore } from "store"
import ShareDialog from "components/ShareDialog"
import IconButton from "components/Buttons/IconButton"
import ApartmentCard from "components/ApartmentCard"
import ShareIcon from "assets/icons/share.svg"
import BackIcon from "assets/icons/back_white.svg"
import NodataImage from "assets/images/favourites/nodata.svg"
import { AnimatePresence, motion } from "framer-motion"
import PrintShare from "pages/Share/components/PrintShare"
import { Navigation, Pagination } from "swiper"
import RefreshIcon from "assets/icons/icon_refresh.svg"
import useCheckInternetConnection from "utils/hooks/useCheckInternetConnection"
import NoWifiIcon from "assets/icons/nowifi.svg"
import { isTablet } from "react-device-detect"

import "./Favourites.css"
import "swiper/css/bundle"
import ShareDialogIpad from "components/ShareDialog/ShareDialogipad"

export default function Favourites() {
  const favourites = useStore(state => state.favourites)
  const setFavourites = useStore(state => state.setFavourites)
  const favouritesVisible = useStore(state => state.favouritesVisible)
  const setFavouritesVisible = useStore(state => state.setFavouritesVisible)

  const [showDialog, setShowDialog] = useState(false)
  const { isConnected } = useCheckInternetConnection()

  const handleShareClick = () => {
    setShowDialog(true)
  }

  const handleBackClick = () => {
    setFavouritesVisible(false)
  }

  return (
    <AnimatePresence>
      {favouritesVisible && (
        <Container
          transition={{ duration: 0.3 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          {favourites.length === 0 ? (
            <NoData>
              <img src={NodataImage} />
            </NoData>
          ) : (
            <>
              <ClearFavourites
                icon={RefreshIcon}
                onClick={() => {
                  setFavourites([])
                }}
              />
              <Swiper
                className="fav-swiper"
                modules={[Pagination, Navigation]}
                navigation={true}
                pagination={{
                  clickable: true
                }}
                scrollbar={{ draggable: true, hide: true }}
                grabCursor={false}
                preventInteractionOnTransition={false}
                slidesPerView={1.5}
                centeredSlides={true}
                spaceBetween={150}>
                {favourites.map((apartment, index) => (
                  <SwiperSlide key={index}>
                    <ApartmentCard apartment={apartment} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <StyledCarousel
                additionalTransfrom={0}
                swipeable={false}
                draggable={false}
                showDots={false}
                centerMode={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                arrows={false}
                autoPlaySpeed={1}
                keyBoardControl={true}
                customTransition="all 10s linear"
                transitionDuration={10000}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item"
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 2,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}>
                <MarkerItem>favorites</MarkerItem>
                <MarkerItem>favorites</MarkerItem>
                <MarkerItem>favorites</MarkerItem>
                <MarkerItem>favorites</MarkerItem>
              </StyledCarousel>
            </>
          )}

          {favourites.length > 0 ? (
            <>
              {isConnected !== null ? (
                isConnected === "online" ? (
                  <ShareButton icon={ShareIcon} onClick={handleShareClick} />
                ) : (
                  <ShareButton
                    icon={NoWifiIcon}
                    style={{
                      background: "#fff",
                      borderRadius: "50px"
                    }}
                  />
                )
              ) : (
                ""
              )}

              {isTablet ? (
                <ShareDialogIpad
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                />
              ) : (
                <ShareDialog
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                />
              )}
              <PrintShare />
            </>
          ) : null}

          <BackButton icon={BackIcon} onClick={handleBackClick} />
        </Container>
      )}
    </AnimatePresence>
  )
}

const Container = styled(motion.div)`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #4a531f;
`

const NoData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 70%;
  }
`

const ShareButton = styled(IconButton)`
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1;
  width: 4.5em;
`

const BackButton = styled(IconButton)`
  position: absolute;
  left: 1em;
  bottom: 1em;
  z-index: 1;
  border-radius: 0;
  width: 5.5em;
`

const StyledCarousel = styled(Carousel)`
  width: 100vw;
  position: absolute;
  top: 8vw;
  left: -40vw;
  z-index: 1;
  color: #b1c5ac;
  background-color: #4a531f;
  transform: rotate(-30deg);
  transform-origin: center center;
  font-size: 5vw;
  letter-spacing: 0.1em;
  border: 3px solid #030201;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
`
const MarkerItem = styled.div`
  width: 100%;
`

const ClearFavourites = styled(IconButton)`
  position: absolute;
  right: 0;
  margin: 2em;
  height: 3em;
  z-index: 10;
  cursor: pointer;
  filter: invert(100%) sepia(2%) saturate(7457%) hue-rotate(47deg)
    brightness(107%) contrast(101%);
`
