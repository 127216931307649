import { Suspense, useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import ThemeProvider from "theme"
import { useStore } from "store"
import DefaultLayout from "pages/DefaultLayout"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { getMaxValue, getMinValue } from "utils"
import Page404 from "pages/ErrorPages/Page404"
import { apartmentsStatic } from "dataset/apartmentsStatic"
import rentcafeApi from "api/rentcafeApi"
import useCheckInternetConnection from "utils/hooks/useCheckInternetConnection"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// const ApartmentSheetURL =
//   "https://docs.google.com/spreadsheets/d/e/2PACX-1vStxIxIiJUgdteRHkny1igXr9M0RnAHTR_JGZDX3up9JjcdwW9Wybz8tgsc9MS1ZkneKqbyf4yC2HWo/pub?gid=249000452&single=true&output=csv"

function App() {
  const setApartments = useStore(state => state.setApartments)
  const setApartmentIndex = useStore(state => state.setApartmentIndex)

  const setMinPrice = useStore(state => state.setMinPrice)
  const setMaxPrice = useStore(state => state.setMaxPrice)
  const setPriceRange = useStore(state => state.setPriceRange)

  const setMinLevel = useStore(state => state.setMinLevel)
  const setMaxLevel = useStore(state => state.setMaxLevel)
  const setLevelRange = useStore(state => state.setLevelRange)

  const apiApartments = useStore(state => state.apiApartments)
  const setApiApartments = useStore(state => state.setApiApartments)

  const { isConnected } = useCheckInternetConnection()

  useEffect(() => {
    console.info("ENV", process.env.NODE_ENV)
    if (process.env.NODE_ENV === "production") {
      window.oncontextmenu = e => {
        e.preventDefault()
      }
    }
  }, [])

  useEffect(() => {
    getAvailability()
  }, [isConnected])

  useEffect(() => {
    processCSV()
  }, [apiApartments])

  const getAvailability = async () => {
    if (isConnected !== null) {
      if (isConnected === "online") {
        const response = await rentcafeApi.getAvailableApartments()

        if (response[0].Error) {
          console.log(response[0])
          return
        }

        localStorage.setItem("apartmentsOffline", JSON.stringify(response))

        setApiApartments(response)
      } else {
        const offlineApartments = JSON.parse(
          localStorage.getItem("apartmentsOffline")
        )

        setApiApartments(offlineApartments)
      }
    }
  }

  const processCSV = () => {
    let data = []

    apiApartments.forEach(apiApt => {
      let apName = apiApt.ApartmentName

      while (apName.charAt(0) === "0") {
        apName = apName.substring(1)
      }

      const newState = apartmentsStatic.map(ap => {
        if (ap["unit #"] === apName.toString()) {
          ap["Availability"] = "0" //"0" means available...
          ap["Price"] = apiApt.MinimumRent
          ap["SqFt"] = apiApt.SQFT
        }

        return ap
      })

      data = newState
    })

    // Set Filtering Values
    const minPrice = 2000 // Hide apartments under $2000. This might be temporary.
    // const minPrice = getMinValue(apartments, [APARTMENT_FIELDS.price])
    const maxPrice = getMaxValue(apartmentsStatic, [APARTMENT_FIELDS.price])
    const minLevel = getMinValue(apartmentsStatic, [APARTMENT_FIELDS.level])
    const maxLevel = getMaxValue(apartmentsStatic, [APARTMENT_FIELDS.level])
    // const offset = 500
    setLevelRange([minLevel, maxLevel])
    setMinLevel(minLevel)
    setMaxLevel(maxLevel)

    setMinPrice(Math.floor(minPrice / 100) * 100)
    setMaxPrice(Math.floor(maxPrice / 100) * 100)

    setPriceRange([
      Math.floor(minPrice / 100) * 100,
      Math.floor(maxPrice / 100) * 100
    ])

    setApartments(data)

    const index = {}
    apartmentsStatic.map(ap => (index[ap[APARTMENT_FIELDS.unitCode]] = ap))
    setApartmentIndex(index)
  }

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
