import styled from "styled-components"
import ApartmentFinderBtn from "components/FinderMenu"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import AnimatedBorderButton from "components/AnimatedBorderButton"
import IconButton from "components/Buttons/IconButton"
import HomeIcon from "assets/icons/home.svg"

import routes from "../../routes"

export default function Navbar({ isNavbarVisible }) {
  const navigate = useNavigate()
  const location = useLocation()
  const isNotVisible = location.pathname === "/"

  const handleClick = v => {
    setTimeout(() => navigate(v), 450)
  }

  return (
    <>
      <NavContainer
        isNavbarVisible={location.pathname !== "/" || !isNavbarVisible}>
        <HomeButton
          icon={HomeIcon}
          onClick={() => {
            navigate("/")
          }}
        />
        {routes.map(item => {
          return item && item.isOnNavbar ? (
            <AnimatedBorderButton
              key={item.name}
              active={location.pathname === item.path}
              onClick={() => handleClick(item.path)}
              route={item.path}>
              {item.name}
            </AnimatedBorderButton>
          ) : null
        })}

        <ApartmentFinderBtn isNotVisible={isNotVisible} />
      </NavContainer>
    </>
  )
}

const HomeButton = styled(IconButton)`
  position: absolute;
  left: 0.5em;
  bottom: 0.7em;
  pointer-events: all;
  width: 4.2vh;
  height: 4.2vh;
  padding: 0;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
`

const NavContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  height: 7vh;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: ${props => (props.isNavbarVisible ? "" : "none")};
`
