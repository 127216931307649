import styled from "styled-components"
import { useStore } from "store"
import ApartmentFinderLayout from "components/ApartmentFinder/layout/ApartmentFinderLayout"
import { animated } from "@react-spring/web"
import { useSpring } from "@react-spring/core"

export default function ApartmentFinder() {
  const apartments = useStore(state => state.apartments)
  const apartmentIndex = useStore(state => state.apartmentIndex)

  const finderVisible = useStore(state => state.finderVisible)
  const setFilterVisible = useStore(state => state.setFilterVisible)

  const setBuildingSceneLoaded = useStore(state => state.setBuildingSceneLoaded)
  const setApartmentSceneLoaded = useStore(
    state => state.setApartmentSceneLoaded
  )

  const HandleRest = () => {
    setFilterVisible(finderVisible)
    setTimeout(() => {
      setApartmentSceneLoaded(finderVisible)
      setBuildingSceneLoaded(finderVisible)
    }, 500)
  }

  const { y, opacity } = useSpring({
    y: finderVisible ? "0vh" : "100vh",
    onRest: () => HandleRest(),
    config: { clamp: true }
  })

  return (
    <AfHolder style={{ bottom: y, opacity: opacity }}>
      {apartments && Object.keys(apartmentIndex).length ? (
        <ApartmentFinderLayout />
      ) : null}
    </AfHolder>
  )
}

const AfHolder = styled(animated.div)`
  position: fixed;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
