import styled from "styled-components"
import { memo } from "react"
import theme from "theme/theme.js"
import FavouritesButton from "components/FavMenu"
import { apartmentFinderTheme } from "../config/styles"
import BuildingScene from "../components/building/model/BuildingScene"
import ApartmentsAvailableList from "../components/apartmentsList/ApartmentsAvailableList"
import { ApartmentsLayout } from "../components/apartments/ApartmentsLayout"
import { CardLayout } from "../components/apartments/Apartments2DCards/CardLayout"
import Filtering from "components/ApartmentFinder/components/filtering"
import ParkLogo from "assets/svg/park151logo.svg"
import ApartmentInfo, {
  ApartmentTitle
} from "../components/apartmentsList/ApartmentInfo"
import Div100vh from "react-div-100vh"
import FloorplanDialogue from "../components/apartments/FloorplanDialogue/FloorplanDialogue"
import IconButton from "components/Buttons/IconButton"
import CloseIcon from "assets/icons/close.svg"
import { useStore } from "store"
import ColourKeySvg from "assets/svg/colour-key.svg"
import ViewCard from "components/ApartmentCard/ViewCard"

const ApartmentFinderLayout = memo(function ApartmentFinderLayout() {
  return (
    <Holder>
      <ViewCard />
      <BuildingUI>
        <ApartmentsContainer>
          <ApartmentsLayoutContainer>
            <FloorplanDialogue />
            <ApartmentTitle />
            <ApartmentsLayout />
            <CardLayout />
          </ApartmentsLayoutContainer>
        </ApartmentsContainer>

        <BuildingInfoContainer>
          <BuildingContainer>
            <BuildingTitle>
              <Logo src={ParkLogo} />
              <ColourKey src={ColourKeySvg} />
            </BuildingTitle>
            <BuildingScene />
          </BuildingContainer>
          <ApartmentsInfoContainer>
            <ApartmentInfo />
            <ApartmentsAvailableList />
          </ApartmentsInfoContainer>
        </BuildingInfoContainer>
      </BuildingUI>
      <Filter />
      <CloseApF />
      <FavouritesButton />
    </Holder>
  )
})

export default ApartmentFinderLayout

function CloseApF() {
  const finderVisible = useStore(state => state.finderVisible)
  const setFinderVisible = useStore(state => state.setFinderVisible)
  const filterVisible = useStore(state => state.filterVisible)
  const setFilterVisible = useStore(state => state.setFilterVisible)

  const handleClick = () => {
    setFinderVisible(!finderVisible)
    filterVisible && setFilterVisible(false)
  }

  return <Button icon={CloseIcon} onClick={handleClick} />
}

function Filter() {
  const activeType = useStore(state => state.activeType)
  return <Filtering showFiltersBar={!activeType} />
}

const Button = styled(IconButton)`
  position: absolute;
  right: 0.8em;
  bottom: 0.2em;
  pointer-events: all;
  width: 4vh;
  height: 4vh;
  padding: 0;
  transition: all 0.2s ease-out;
  &:hover {
    opacity: 0.6;
  }
`

export const Holder = styled(Div100vh)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  user-select: none;
  font-size: 2rem;
  letter-spacing: 1rem;
  overflow: hidden;
`

const BuildingTitle = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: GT-Sectra-Light;
  font-size: 10rem;
  padding: 0.75rem;
  opacity: 0.6;
`

const Logo = styled.img`
  width: 80%;
  object-fit: cover;
  object-position: left top;
`

const BuildingUI = styled.div`
  position: absolute;
  background-color: ${theme.colors.finderBgColor};
  box-sizing: border-box;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 5vh;
`

export const ApartmentsContainer = styled.div`
  padding: 1.75rem;
  padding-bottom: 0.5vh;
  padding-right: 0;
  left: 0;
  height: 100%;
  width: ${apartmentFinderTheme.splitPercentage}vw;
`
export const ApartmentsLayoutContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
`
export const BuildingInfoContainer = styled.div`
  padding: 1.75rem;
  padding-bottom: 0.5vh;
  left: ${apartmentFinderTheme.splitPercentage}%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: ${100 - apartmentFinderTheme.splitPercentage}vw;
  height: 100%;
  gap: 1.75rem;
`

export const ApartmentsInfoContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
  flex: 1;
  border: 1px solid ${theme.colors.borderColor};
  position: relative;
`
export const BuildingContainer = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid ${theme.colors.borderColor};
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    z-index: 0;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 1px solid ${theme.colors.borderColor};
  }
`

const ColourKey = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
`
