import styled from "styled-components"
import { useTransition, animated as a } from "@react-spring/web"
import { useStore } from "store"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import FloorPlanIcon from "assets/icons/floor-plan-icon.svg"
import ViewIcon from "assets/icons/view-icon.svg"
import { priceFormatter } from "utils/convertDataFormat"
import IsFaveIcon from "components/Icons/IsFaveIcon"
// import { useEffect, useState } from "react"
import { BEDS, FLOORS } from "dataset/nameMappers"
import { viewIndex } from "dataset/viewIndex"

const values = ["SQFT", "BED", "BATH", "PRICE"]

export function ApartmentTitle() {
  const activeApartment = useStore(s => s.activeApartment)

  const transition = useTransition(activeApartment, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  if (activeApartment) {
    return transition(
      (style, item) =>
        item && (
          <HeaderHolder>
            <TitleHolder style={{ style }}>
              <h1 style={{ fontSize: "1.5rem" }}>
                UNIT {activeApartment[APARTMENT_FIELDS.unitCode]}{" "}
              </h1>
            </TitleHolder>
            <FloorHolder style={{ style, marginBottom: 0 }}>
              {FLOORS[activeApartment[APARTMENT_FIELDS.level]].toUpperCase()}{" "}
              FLOOR
            </FloorHolder>
            <FloorHolder style={{ style, marginTop: 0 }}>
              <h1
                style={{
                  fontSize: "1.2rem",
                  color: "#f46f43",
                  fontFamily: "Atlas-Grotesk-Light"
                }}>
                TYPE {activeApartment[APARTMENT_FIELDS.type]}{" "}
              </h1>
            </FloorHolder>
          </HeaderHolder>
        )
    )
  } else return null
}

const HeaderHolder = styled.div`
  z-index: 29;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const TitleHolder = styled(a.div)`
  width: fit-content;
  height: fit-content;
  padding: 0.6vw;
  /* background: hotpink; */
  font-family: GT-Sectra-Light;
  font-size: 2.5vw;
  margin: 1.75rem;
  margin-bottom: 0.5rem;
  border: black solid 1px;
  border-radius: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`

const FloorHolder = styled(a.div)`
  width: fit-content;
  height: fit-content;
  padding: 0.7vw;
  font-family: GT-Sectra-Light;
  font-size: 1.5vw;
  letter-spacing: 0.5vw;
  margin: 1.75rem;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
function FloorplanButton() {
  const setFloorplanDialogue = useStore(s => s.setFloorplanDialogue)
  const floorplanDialogue = useStore(s => s.floorplanDialogue)

  return (
    <IconHolder onClick={() => setFloorplanDialogue(!floorplanDialogue)}>
      <MarkerHolder height="100%" width="100%" src={FloorPlanIcon} alt="" />
    </IconHolder>
  )
}
function ViewButton({ activeApartment }) {
  const setViewOpenID = useStore(s => s.setViewOpenID)

  return (
    <IconHolder
      onClick={() =>
        setViewOpenID(viewIndex[activeApartment?.[APARTMENT_FIELDS.unitCode]])
      }>
      <MarkerHolder height="100%" width="100%" src={ViewIcon} alt="" />
    </IconHolder>
  )
}

function ApartmentInfo() {
  const activeApartment = useStore(s => s.activeApartment)

  const transition = useTransition(activeApartment, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  if (activeApartment) {
    return transition(
      (style, item) =>
        item && (
          <InfoHolder style={style}>
            <Header>
              {values.map(i => (
                <ValueHolder
                  key={i}
                  style={{
                    fontFamily: "GT-Sectra-Light",
                    justifyContent: "flex-end",
                    flexDirection: "column"
                  }}>
                  {i}
                </ValueHolder>
              ))}
            </Header>
            <Values>
              <ValueHolder>
                {activeApartment[APARTMENT_FIELDS.area]}
              </ValueHolder>
              <ValueHolder>
                {BEDS[activeApartment[APARTMENT_FIELDS.beds]]}
              </ValueHolder>
              <ValueHolder>
                {activeApartment[APARTMENT_FIELDS.bath]}
              </ValueHolder>
              <ValueHolder>
                {priceFormatter.format(activeApartment[APARTMENT_FIELDS.price])}
                /m
              </ValueHolder>
            </Values>
            <Buttons
              style={{
                fontFamily: "GT-Sectra-Light",
                justifyContent: "center"
              }}>
              <IconHolder>
                <IsFaveIcon activeApartment={activeApartment} />
              </IconHolder>
              <FloorplanButton />
              <ViewButton activeApartment={activeApartment} />
            </Buttons>
          </InfoHolder>
        )
    )
  } else return null
}

export default ApartmentInfo

const MarkerHolder = styled.img`
  height: 100%;
  width: 100%;
`

const IconHolder = styled.div`
  position: relative;
  height: 4.5vh;
  width: 4.5vh;
  cursor: pointer;
`

const ValueHolder = styled.div`
  /* border: pink solid 1px; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Circular-Std;
`

const InfoHolder = styled(a.div)`
  /* border: orange solid 1px; */
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 2rem;
  justify-content: space-between;
  align-items: space-between; ;
`

const Header = styled.div`
  /* border: orange solid 1px; */
  flex: 1;
  display: flex;
  font-size: 1.5vw;
  letter-spacing: 0.5vw;
`
const Values = styled.div`
  /* border: orange solid 1px; */
  flex: 1;
  display: flex;
  font-size: 1.2vw;
  letter-spacing: 0.35vw;
`
const Buttons = styled.div`
  /* border: orange solid 1px; */
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 3vw;
`
