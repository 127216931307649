import React, { useState, useRef, useEffect } from "react"
import Keyboard from "react-simple-keyboard"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { device } from "theme/device"
import { useStore } from "store"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import IconButton from "components/Buttons/IconButton"
import Loader from "components/Loader"
import CloseIcon from "assets/icons/close.svg"
import DownloadIcon from "assets/icons/download-icon.svg"
import EmailIcon from "assets/icons/email-icon.svg"
import LinkIcon from "assets/icons/link-icon.svg"
import "react-simple-keyboard/build/css/index.css"
import "./VMIKeyboard.css"
import "./ShareDialog.css"

const bccAddress = {
  Test1: "matthewosborne@live.com",
  Test2: "dsgnrm@gmail.com"
}

export default function ShareDialogIpad({ showDialog, setShowDialog }) {
  const favourites = useStore(state => state.favourites)
  const [copied, setCopied] = useState(false)
  const [sending, setSending] = useState(false)
  const [bcc, setBcc] = useState([])

  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const keyboard = useRef()

  useEffect(() => {
    return () => {
      // Clean up inputs if dialog is closed
      setInputs({})
    }
  }, [showDialog])

  useEffect(() => {
    if (keyboard.current) {
      const hideButton = keyboard.current.getButtonElement("{hide}")

      hideButton.addEventListener("click", () => {
        keyboard.current.keyboardDOM.classList.add("hidden")
      })
    }
  })

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const onChangeInput = event => {
    const inputVal = event.target.value

    setInputs({
      ...inputs,
      [inputName]: inputVal
    })

    keyboard.current?.setInput(inputVal)
  }

  const getInputValue = inputName => {
    return inputs[inputName] || ""
  }

  const favouriteIDs = []
  favourites.map(f => favouriteIDs.push(f[APARTMENT_FIELDS.unitCode]))

  const url = `${window.location.origin}/share?units=${favouriteIDs.join(",")}`

  const handleSendemail = e => {
    setSending(true)
    e.preventDefault()
    const names = getInputValue("name").split(" ")
    const emailURL =
      "https://qqo4y7jd7c.execute-api.eu-west-2.amazonaws.com/dev/send"
    const apiKey = "SuslEuh0MU9WUeGbEjF6N83RLw84ikWq5pQqw7CK"
    fetch(emailURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from: "leasing@park151.com",
        // from: "matthewosborne@live.com",
        to: getInputValue("email"),
        bcc: [bccAddress[bcc[0]], bccAddress[bcc[1]]],
        template: "park151",
        language: "en",
        project: "park151",
        data: {
          firstname: names[0],
          lastname: names.slice(1).join(" "),
          link: url
        }
      })
    }).then(() => {
      setSending(false)
    })
  }

  const copyToClipboard = string => {
    let textarea
    let result

    try {
      textarea = document.createElement("textarea")
      textarea.setAttribute("readonly", true)
      textarea.setAttribute("contenteditable", true)
      textarea.style.position = "fixed"
      textarea.value = string
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      const range = document.createRange()
      range.selectNodeContents(textarea)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      textarea.setSelectionRange(0, textarea.value.length)
      result = document.execCommand("copy")
    } catch (err) {
      result = null
    } finally {
      document.body.removeChild(textarea)
    }

    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
      const copyHotkey = isMac ? "⌘C" : "CTRL+C"
      result = prompt(`Press ${copyHotkey}`, string)

      if (!result) {
        return false
      }
    }

    setCopied(!copied)

    // setOpenShareDialog(false)
    setSending(false)

    return true
  }

  const handleCopylink = e => {
    copyToClipboard(url)
  }

  async function handleDownload() {
    const input = document.getElementById("print-share-page")
    const e_w = input.clientWidth
    const e_h = input.clientHeight

    const d_w = 210
    const d_h = (e_h * d_w) / e_w

    const doc = new jsPDF("p", "mm", [d_w, d_h], true)

    await html2canvas(input, {
      dpi: 144,
      x: 0,
      y: 0,
      width: e_w,
      height: e_h
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0)
      doc.addImage(imgData, "JPEG", 0, 0, d_w, d_h, undefined, "FAST")
    })

    doc.save(`Your Apartments at Park 151.pdf`)
  }

  return (
    <>
      <AnimatePresence>
        {showDialog && (
          <DialogHolder style={{ maxHeight: "none" }}>
            <motion.div
              className="share-form"
              transition={{ duration: 0.5 }}
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}>
              <CloseButton
                icon={CloseIcon}
                onClick={() => {
                  setShowDialog(false)
                  keyboard.current.keyboardDOM.classList.add("hidden")
                }}
              />
              <Content>
                <Title>Share</Title>
                <ItemHolder>
                  <EmailGroup>
                    <input
                      autoComplete="off"
                      inputMode="none"
                      id="name"
                      className="share-input"
                      type="text"
                      value={getInputValue("name")}
                      onFocus={() => {
                        setInputName("name")
                        keyboard.current?.keyboardDOM.classList.remove("hidden")
                      }}
                      placeholder={"Name"}
                      onChange={onChangeInput}
                      required={true}
                      autoFocus={true}
                    />
                    <input
                      autoComplete="off"
                      inputMode="none"
                      id="email"
                      value={getInputValue("email")}
                      type="email"
                      placeholder="Email"
                      className="share-input"
                      onFocus={() => {
                        setInputName("email")
                        keyboard.current?.keyboardDOM.classList.remove("hidden")
                      }}
                      onChange={onChangeInput}
                      required={true}
                    />
                  </EmailGroup>
                  <Controls>
                    <img
                      className="share-control"
                      src={DownloadIcon}
                      alt="Download a pdf"
                      onClick={handleDownload}
                    />
                    {sending ? (
                      <Loader size={50} />
                    ) : (
                      <>
                        <img
                          className="share-control"
                          src={EmailIcon}
                          alt="Send email"
                          onClick={handleSendemail}
                        />
                        <AnimatePresence>
                          <motion.div
                            key={sending}
                            style={{
                              position: "absolute",
                              right: "150px",
                              bottom: 0,
                              fontSize: "0.7em"
                            }}
                            animate={{ opacity: 0, y: -30 }}
                            initial={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.75 }}>
                            SENT
                          </motion.div>
                        </AnimatePresence>
                      </>
                    )}
                    <img
                      className={`share-control`}
                      src={LinkIcon}
                      alt="Copy link"
                      onClick={handleCopylink}
                    />
                    <AnimatePresence>
                      <motion.div
                        style={{
                          position: "absolute",
                          right: "45px",
                          bottom: 0,
                          fontSize: "0.7em"
                        }}
                        key={copied}
                        animate={{ opacity: 0, y: -30 }}
                        initial={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.75 }}>
                        COPIED
                      </motion.div>
                    </AnimatePresence>
                  </Controls>
                </ItemHolder>
              </Content>
            </motion.div>

            <Keyboard
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} q w e r t y u i o p [ ] \\",
                  "{lock} a s d f g h j k l ; ' {enter}",
                  "{shift} z x c v b n m , . / {shift}",
                  ".com @ {space} {hide}"
                ],
                shift: [
                  "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M &lt; &gt; ? {shift}",
                  ".com @ {space} {hide}"
                ]
              }}
              display={{
                "{enter}": "&#8617;",
                "{bksp}": "&#10229;",
                "{tab}": "Tab",
                "{lock}": "Caps Lock",
                "{shift}": "Shift",
                "{space}": "Space",
                "{hide}": "&#10549;"
              }}
              tabCharOnTab={false}
              theme={`hg-theme-default`}
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
            />
          </DialogHolder>
        )}
      </AnimatePresence>
    </>
  )
}

const DialogHolder = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #000000aa;
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  z-index: 1;
  width: 2.5em;
  height: 2.5em;
  background-color: transparent;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
  font-family: Atlas-Grotesk-Light;
  @media ${device.pad} {
    flex-direction: column;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 4em;
  letter-spacing: 0.1em;
  color: white;
  padding: 1em 0.5em;
  text-transform: uppercase;
  border-right: 1px solid #030201;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  @media ${device.pad} {
    border-right: none;
    padding: 0.4em 0.5em;
  }
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 1.5em 0.5em 1.5em;
  font-size: 1.2em;
  width: 20em;
`
const EmailGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
