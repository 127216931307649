import React from "react"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import { useStore } from "store"
import styled from "styled-components"
import CloseIcon from "assets/icons/close.svg"
import BackIcon from "assets/icons/back.svg"
import { useState } from "react"

function ViewCard() {
  const viewOpenID = useStore(s => s.viewOpenID)
  const setViewOpenID = useStore(s => s.setViewOpenID)

  const transition = useTransition(viewOpenID, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })
  return transition(
    (styles, item) =>
      item && (
        <ViewWr onClick={() => setViewOpenID(null)} style={styles}>
          <CloseWrapper onClick={() => setViewOpenID(null)}>
            <CloseButton height="100%" src={CloseIcon} />
          </CloseWrapper>
          {viewOpenID === "null" ? (
            <NoView>View Coming Soon</NoView>
          ) : (
            <Views viewOpenID={viewOpenID} />
          )}
        </ViewWr>
      )
  )
}

function Views({ viewOpenID }) {
  const isMultiView = viewOpenID?.split("%").length > 1

  if (isMultiView) {
    return <MultiAspectView viewOpenID={viewOpenID} />
  } else
    return (
      <View
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/views/${viewOpenID}.jpg)`
        }}></View>
    )
}
function MultiAspectView({ viewOpenID }) {
  const viewArr = viewOpenID?.split("%")

  const [viewID, set] = useState(0)

  const handleBack = e => {
    e.stopPropagation()
    if (viewID !== 0) {
      set(viewID - 1)
    }
  }
  const handleFwd = e => {
    e.stopPropagation()
    if (viewID !== viewArr.length - 1) {
      set(viewID + 1)
    }
  }

  return (
    <>
      <SwitchWr>
        <img
          style={{ opacity: viewID === 0 ? 0.3 : 1 }}
          onClick={handleBack}
          height="100%"
          src={BackIcon}
          alt="left"
        />
        <img
          style={{ opacity: viewID === viewArr.length - 1 ? 0.3 : 1 }}
          onClick={handleFwd}
          id="flip"
          height="100%"
          src={BackIcon}
          alt="right"
        />
      </SwitchWr>
      <View
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/views/${viewArr[viewID]}.jpg)`
        }}></View>
    </>
  )
}

const CloseWrapper = styled.div`
  cursor: pointer;
  right: 0.8em;
  padding: 0;
  bottom: 0.2em;
  z-index: 39;
  position: absolute;
  width: 4vh;
  height: 4vh;
`

const CloseButton = styled.img``

const SwitchWr = styled.div`
  width: 80vw;
  position: absolute;
  bottom: 0%;
  right: 1.75rem;
  height: 5.5vh;
  display: flex;
  justify-content: center;
  gap: 2em;
  align-items: center;
  img {
    transition: opacity 0.3s ease;
    height: 35%;
    cursor: pointer;
  }
  #flip {
    transform: scaleX(-1);
  }
`
const NoView = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const View = styled(a.div)`
  position: absolute;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 5.5vh;
  z-index: 993;
  width: 80vw;
  right: 1.75rem;
  max-height: 70%;

  height: calc(100vh - 1.6em);
  display: flex;
  justify-content: center;
  color: black;
`
const ViewWr = styled(a.div)`
  position: absolute;
  /* padding: 0.7vw; */
  z-index: 12;
  right: 0;
  height: 100%;

  width: 100vw;
  background-color: #ffffffdd;
`

export default ViewCard
