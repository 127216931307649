import styled from "styled-components"
import useMeasure from "react-use-measure"
import IconButton from "components/Buttons/IconButton"
import FavIcon from "assets/icons/fav.svg"
import { useStore } from "store"
import { device } from "theme/device"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { PUBLIC_ASSET_PATH } from "dataset/common"
import { BEDS, FLOORS } from "dataset/nameMappers"
// import { useEffect, useState } from "react"

export default function ApartmentCard({ apartment, isShare = true }) {
  const toggleFavourites = useStore(state => state.toggleFavourites)

  return (
    <Holder>
      <PartHolder>
        <Unit>{`UNIT ${apartment[APARTMENT_FIELDS.unitCode]}`}</Unit>

        <Floor>{FLOORS[apartment[APARTMENT_FIELDS.level]]} Floor</Floor>
        <Floor style={{ color: "#F77043" }}>
          TYPE {apartment[APARTMENT_FIELDS.type]}{" "}
        </Floor>
      </PartHolder>
      <PlanViewer
      // ref={planRef}
      >
        <Plan
          src={`${PUBLIC_ASSET_PATH}/svgfloorplans/${
            apartment[APARTMENT_FIELDS.type]
          }.png`}
          alt=""
          // islandscape={isLandscape}
        />
      </PlanViewer>
      <PartHolder>
        <Item>
          <Key>SQFT</Key>
          <Value>{apartment[APARTMENT_FIELDS.area]}</Value>
        </Item>
        <Item>
          <Key>BEDS</Key>
          <Value>{BEDS[apartment[APARTMENT_FIELDS.beds]]}</Value>
        </Item>
        <Item>
          <Key>BATH</Key>
          <Value>{apartment[APARTMENT_FIELDS.bath]}</Value>
        </Item>
        <Item>
          <Key>PRICE</Key>
          <Value>{`$${apartment[APARTMENT_FIELDS.price]}/m`}</Value>
        </Item>
      </PartHolder>
      {isShare && (
        <FavButton icon={FavIcon} onClick={() => toggleFavourites(apartment)} />
      )}
    </Holder>
  )
}

const Holder = styled.div`
  min-height: 700px;
  cursor: grab;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 8px solid #b1c5ac;
  gap: 1vw;
  background-color: #fafbf5;
  font-size: 1.5vh;
  @media ${device.pad} {
    flex-direction: column;
    font-size: 2vw;
  }
`

const FavButton = styled(IconButton)`
  position: absolute;
  bottom: 0.8em;
  right: 0.8em;
  z-index: 1;
`

const PartHolder = styled.div`
  width: 10em;
  height: 100%;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;

  @media ${device.pad} {
    font-size: 2vw;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
`

const Unit = styled.p`
  /* padding: 0.2em 1em; */
  /* flex: 1; */
  border: 1px solid #030201;
  border-radius: 0.5em;
  text-transform: uppercase;
  font-family: GT-Sectra-Light;
  color: #030201;
  margin: 0;
  padding: 5px;
  margin-bottom: 1.75vh;
  @media ${device.pad} {
    font-size: 1.5rem;
    margin: 0;
  }
`

const Floor = styled.p`
  padding: 0.2em 1em;
  text-transform: uppercase;
  color: #030201;
  margin: 0;
  edia ${device.pad} {
    font-size: 3rem;
    margin: 0;
  }
`

const PlanViewer = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  /* border: red solid 2px; */
`

const Plan = styled.img`
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 1em;
  object-fit: contain;
`

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.5vh 0;
  @media ${device.pad} {
    margin-bottom: 1vw;
  }
`

const Key = styled.p`
  padding: 0;
  margin: 0 0 0.8em 0;
  text-transform: uppercase;
  font-family: GT-Sectra-Light;
  font-size: 1em;
  color: #030201;
`

const Value = styled.p`
  padding: 0;
  margin: 0;
  font-family: Atlas-Grotesk-Regular;
  color: #030201;
`
