import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle
} from "styled-components"
import { normalize } from "polished"
import theme from "./theme"
import Div100vh from "react-div-100vh"
import { isMobile } from "react-device-detect"

// Load primereact styles
import "./tailwind-light-theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: ${theme.colors.finderTextColor};
    background-color: ${theme.colors.lightGrey};
    display: flex;
    flex-direction: column;
    touch-action: none;
    font-family: Circular-Std;
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
  }

  *::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
    border-radius: 0px;
  }
  *::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #676767;
    border-radius: 0px;
  }
`
const MobileContainer = styled(Div100vh)`
  width: 100vw;
`

const DesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
`

const ApplicationThemeProvider = ({ children }) => (
  <StyledComponentsThemeProvider theme={theme}>
    <GlobalStyle />
    {isMobile ? (
      <MobileContainer> {children} </MobileContainer>
    ) : (
      <DesktopContainer> {children} </DesktopContainer>
    )}
  </StyledComponentsThemeProvider>
)

export default ApplicationThemeProvider
