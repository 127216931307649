import { OrbitControls } from "@react-three/drei"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Suspense, useLayoutEffect, useRef, useCallback } from "react"
import { Spherical, Vector3 } from "three"

import { useStore } from "store"
import ApartmentModelsNew from "../loaders/ApartmentModelsNew"

const camera = {
  zoom: 0.6,
  near: 0.001,
  far: 12000,
  position: [0, 400, 1000]
}

function ControlSpin({ compassCallback }) {
  const setCameraRotation = useStore(state => state.setCameraRotation)
  var dir = new Vector3()
  var sph = new Spherical()

  useFrame(({ camera }) => {
    camera.getWorldDirection(dir)
    sph.setFromVector3(dir)

    setCameraRotation(sph.setFromVector3(dir).theta)
    compassCallback(sph.setFromVector3(dir))
  })

  return null
}

function ApartmentsScene() {
  const compassCallback = useCallback(v => {}, [])

  return (
    <>
      <Canvas
        gl={{
          antialias: true
        }}
        dpr={2}
        orthographic
        camera={camera}>
        <Suspense fallback={null}>
          <ApartmentModelsNew />
        </Suspense>
        <ControlSpin compassCallback={compassCallback} />
        <CameraControls />
        <pointLight position={[600, 600, 600]} intensity={2} color="#BACCB5" />
        <pointLight position={[-600, 0, -600]} intensity={2} color="#D77F72" />
      </Canvas>
    </>
  )
}

function CameraControls() {
  const controls = useRef()
  const activeCamera = true

  const { camera } = useThree()

  document.addEventListener("keydown", e => {
    e.key === "s" &&
      console.log([camera.position.x, camera.position.y, camera.position.z])
  })

  useLayoutEffect(() => {
    camera.position.x = 317
    camera.position.y = 568
    camera.position.z = 268
    controls.current.enabled = activeCamera
    controls.current.target.y = 80
  }, [activeCamera])

  return (
    <OrbitControls
      maxZoom={2}
      minZoom={0.35}
      enablePan={false}
      maxPolarAngle={1.15}
      minPolarAngle={0}
      ref={controls}
    />
  )
}

export default ApartmentsScene
