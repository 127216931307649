import styled from "styled-components"
import ApartmentsScene from "./Apartments3DScene/ApartmentsScene"
import { Scroll3DApt } from "./Apartments3DScene/Scroll3DApartments"
import { useStore } from "store"
import Backicon from "assets/icons/back.svg"

export function ApartmentsLayout() {
  return (
    <>
      <ApartmentSceneContainer>
        <ApartmentsScene />
        <Scroll3DApt />
      </ApartmentSceneContainer>
    </>
  )
}

export function BackExit() {
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const setActiveType = useStore(state => state.setActiveType)
  const setFloorplanDialogue = useStore(state => state.setFloorplanDialogue)
  const activeApartment = useStore(state => state.activeApartment)

  const handleBack = () => {
    setActiveApartment(null)
    setActiveType(null)
    setFloorplanDialogue(false)
  }

  if (activeApartment) {
    return <BackBtn src={Backicon} onClick={handleBack} />
  } else return null
}

export const ApartmentSceneContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const BackBtn = styled.img`
  pointer-events: auto;
  position: absolute;
  width: 5rem;
  height: 5vh;
  bottom: 0;
  cursor: pointer;
  color: black;
`
