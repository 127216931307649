/* eslint-disable import/no-anonymous-default-export */
export default {
  getAvailableApartments: async () => {
    var myHeaders = new Headers()

    myHeaders.append(
      "Cookie",
      "__cf_bm=tyHpaKITH1zadBz8y017KL_VmRwoSH39aJlWSnw7q7E-1654701489-0-AWEpZq3G7yVJDmo6t5TAuRwhqxHcz3NZrfRge/ETHLQijsOsuBWMmXdqAd55sRR8oYR4jPrhEAeskLZcQsg2PK4="
    )

    const response = await fetch(
      "https://api.rentcafe.com/rentcafeapi.aspx?requestType=apartmentAvailability&propertyId=1523775&apiToken=7f9afa54-48f4-4426-92cc-70720e122a72"
    )

    return await response.json()
  }
}
