import styled from "styled-components"
import { useState } from "react"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { flexArr, titles } from "components/ApartmentFinder/config/titles"
import { useStore } from "store"
import { priceFormatter } from "utils/convertDataFormat"
import { useAvailableApartments } from "utils/hooks"
import theme from "theme/theme"
import { device } from "theme/device"
import { sortBy } from "lodash-es"
import HeartIcon from "assets/svg/heart-icon.svg"
import HeartIconOn from "assets/svg/heart-icon-on.svg"
import ArrowIcon from "components/Icons/ArrowIcon"
import { BEDS } from "dataset/nameMappers"

function ApartmentsAvailableList() {
  const availableApartments = useAvailableApartments()

  // const filteredIndex = useFilteredIndex()
  // console.log(filteredIndex)
  // const isAvailable = filteredIndex.includes(unitID)

  const activeApartment = useStore(state => state.activeApartment)
  const [orderState, setOrderState] = useState("APT")

  // TODO: ADD SORT BY TYPE SO AGENTS DONT HAVE TO SCROLL EVERTHING
  //TODO : MAJOR PERFOMANCE HIT ORDERING CALLED EVERY UPDATE OF FILTERING

  const orderByPrice = sortBy(availableApartments, [
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.price])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.beds])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.level])
    }
  ])

  const orderByBeds = sortBy(availableApartments, [
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.beds])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.price])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.number])
    }
  ])

  const orderByApartment = sortBy(availableApartments, [
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.number])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.beds])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.level])
    }
  ])
  const orderByFloor = sortBy(availableApartments, [
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.level])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.price])
    },
    function (o) {
      return parseInt(o[APARTMENT_FIELDS.beds])
    }
  ])

  const doNothing = () => {
    return
  }
  const handleSetOrder = v => {
    setOrderState(v)
  }

  const orderTypes = {
    BEDS: orderByBeds,
    TYPE: orderByBeds,
    MONTHLY: orderByPrice,
    APT: orderByApartment,
    FLOOR: orderByFloor
  }
  //test
  return (
    <>
      {!activeApartment && (
        <ColumnHeaders
          orderState={orderState}
          handleSetOrder={handleSetOrder}
        />
      )}
      {!activeApartment && (
        <div>
          {orderTypes[orderState].map((ap, i) => (
            <TableItem apartment={ap} key={i} />
          ))}
        </div>
      )}
    </>
  )
}

function TableItem({ apartment }) {
  const protoFaves = useStore(state => state.protoFaves)
  const activeApartment = useStore(state => state.activeApartment)
  const toggleProtoFaves = useStore(state => state.toggleProtoFaves)
  const toggleFavourites = useStore(state => state.toggleFavourites)
  const setActiveApartment = useStore(state => state.setActiveApartment)

  const favourites = useStore(state => state.favourites)

  const handleClickHeart = (e, apartment) => {
    toggleFavourites(apartment)
    e.stopPropagation()
  }

  const isFave = favourites.some(
    e => e[APARTMENT_FIELDS.unitCode] === apartment[APARTMENT_FIELDS.unitCode]
  )
  const data = [
    // returnPostAddress(apartment).replace(/  +/g, " "),
    apartment[APARTMENT_FIELDS.unitCode],
    apartment[APARTMENT_FIELDS.type],
    priceFormatter.format(apartment[APARTMENT_FIELDS.price]),
    BEDS[apartment[APARTMENT_FIELDS.beds]],
    apartment[APARTMENT_FIELDS.aspect],
    apartment[APARTMENT_FIELDS.level],
    <img
      onClick={e => handleClickHeart(e, apartment)}
      alt="fav"
      style={{
        width: "2em",
        position: "relative",
        zIndex: 10,
        pointerEvents: "initial"
      }}
      src={isFave ? HeartIconOn : HeartIcon}></img>
  ]

  const onClick = e => {
    e.stopPropagation()
    toggleProtoFaves(apartment)
  }
  const onDoubleClick = e => {
    e.stopPropagation()
    setActiveApartment(apartment)
  }

  const isActive = protoFaves.some(e => e === apartment)

  return (
    <>
      {!activeApartment && (
        <TableRow
          onDoubleClick={e => onDoubleClick(e, apartment)}
          onClick={e => onClick(e)}
          fill={isActive ? "#ecf5ea" : "#00000000"}>
          {data.map((v, i) => (
            <TI fontSize="0.55em" flex={flexArr[i]} key={i}>
              {i === 0 ? (
                <PostalAddContainer>
                  <span
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "1rem"
                    }}>
                    {v}
                  </span>
                </PostalAddContainer>
              ) : (
                <span style={{ fontSize: "0.75rem", margin: "10px" }}>{v}</span>
              )}
            </TI>
          ))}
        </TableRow>
      )}
    </>
  )
}

function ColumnHeaders({ handleSetOrder, orderState }) {
  const activeApartment = useStore(state => state.activeApartment)
  return (
    <>
      {!activeApartment && (
        <ViewportTitle>
          {titles.map((v, i) => (
            <TI
              style={{
                cursor: v !== "FACING" ? "pointer" : "auto",
                fontFamily: "GT-Sectra-Light",
                letterSpacing: "0.15em"
              }}
              onClick={() => {
                v !== "FACING" && handleSetOrder(v)
              }}
              fontSize="0.75em"
              flex={flexArr[i]}
              key={v}>
              {v}
              {v === orderState && (
                <span
                  style={{
                    height: "1.5em",
                    width: "1.5rem",
                    padding: "0.4rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                  <ArrowIcon direction="bottom" />
                </span>
              )}
            </TI>
          ))}
        </ViewportTitle>
      )}
    </>
  )
}

export default ApartmentsAvailableList

const PostalAddContainer = styled.div`
  font-size: 0.9rem;
  display: flex;
`

const TableRow = styled.div`
  height: 2.8rem;
  cursor: pointer;
  opacity: 0.7;
  background-color: ${props => props.fill};
  border-bottom: ${theme.colors.finderTextColor} solid 1px;
  color: ${theme.colors.finderTextColor};
  font-family: Circular-Std;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const TI = styled.div`
  flex: ${props => props.flex};
  /* border: red solid 1px; */
  font-family: Circular-Std;
  /* padding-left: 0.5em; */
  font-size: ${props => props.fontSize};
  letter-spacing: 0.05rem;
  font-weight: ${props => props.fontWeight};
  display: flex;
  /* flex-shrink: 1; */
  /* max-width: 90px; */
  align-items: center;
  justify-content: center;

  @media ${device.mobileL} {
    font-size: 1rem;
    letter-spacing: 0.6rem;
  }
`

const ViewportTitle = styled.div`
  background-color: #c6d1c3;
  height: 3rem;
  color: ${theme.colors.finderTextColor};
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0%;
  z-index: 9;

  & > div {
    display: flex;
    align-items: center;
  }
`
