import React from "react"
import styled from "styled-components"
import { colours } from "theme/colours"
import Skyline from "assets/svg/skyline.svg"
import HorizontalLogo from "assets/svg/logo-horizontal.svg"
import { Link } from "react-router-dom"
import RefreshIcon from "assets/icons/icon_refresh.svg"
import { useNavigate } from "react-router-dom"

export default function Intro() {
  const navigate = useNavigate()

  return (
    <>
      <FullPage
        onClick={() => {
          navigate("/park151")
        }}>
        <LogoButton src={HorizontalLogo} alt="" />
        <BackgroundIllustration src={Skyline} alt="skyline" />
      </FullPage>
      <Refresh
        onClick={() => {
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }}
      />
    </>
  )
}

const Refresh = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  /* border: black solid 1px; */
  left: 1.25em;
  top: 1.25em;
  cursor: pointer;

  background-image: url(${RefreshIcon});
  background-repeat: no-repeat;
  background-size: contain;
`

const LogoButton = styled.img`
  position: absolute;
  max-height: 130px;
  margin: auto auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  cursor: pointer;
  transition: opacity 1s ease-out;
  animation: pulse 1s linear infinite;

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1, 1);
    }
    50% {
      -webkit-transform: scale(0.99, 0.99);
    }
    100% {
      -webkit-transform: scale(1, 1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.99, 0.99);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`

const FullPage = styled.div`
  position: absolute;
  background-color: ${colours.secondary.sunset};
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;

  #button {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  #button2 {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const BackgroundIllustration = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: -50px;
  position: absolute;
`
