import styled from "styled-components"
import { useStore } from "store"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import useMeasure from "react-use-measure"
import { roomColours } from "theme/colours"
import { FLOORS, BEDS_NAMED } from "dataset/nameMappers"
import CloseIcon from "assets/icons/close.svg"
import IsFaveIcon from "components/Icons/IsFaveIcon"

export default function RoomCard({
  apartment,
  isCompare = false,
  isMini = false,
  className
}) {
  const [ref, { width }] = useMeasure()
  const setActiveType = useStore(state => state.setActiveType)
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const activeApartment = useStore(state => state.activeApartment)
  const beds = apartment[APARTMENT_FIELDS.beds]

  const isActive =
    apartment?.[APARTMENT_FIELDS.unitCode] ===
    activeApartment?.[APARTMENT_FIELDS.unitCode]

  const handleClickApartment = () => {
    setActiveApartment(apartment)
    setActiveType(apartment[APARTMENT_FIELDS.type])
  }

  if (isMini) {
    return (
      <StyledMiniCardContainer
        ref={ref}
        onClick={() => {
          handleClickApartment()
        }}
        colour={roomColours[beds]}
        opac={isActive && !isCompare ? "ff" : "44"}
        style={{ fontSize: `${width / 10}px` }}
        className={className}>
        <RoomCardLayout isMini={true} apartment={apartment} />
      </StyledMiniCardContainer>
    )
  } else {
    return (
      <StyledCardContainer
        ref={ref}
        onClick={() => {
          handleClickApartment()
        }}
        colour="#030201"
        opac={isActive && !isCompare ? "ff" : "44"}
        style={{ fontSize: `${width / 10}px` }}
        className={className}>
        <RoomCardLayout apartment={apartment} />
      </StyledCardContainer>
    )
  }
}

function RoomCardLayout({ apartment, isMini = false }) {
  const toggleProtoFaves = useStore(state => state.toggleProtoFaves)
  const unit = apartment[APARTMENT_FIELDS.unitCode]
  const floor = apartment[APARTMENT_FIELDS.level]
  const beds = apartment[APARTMENT_FIELDS.beds]

  const chars = [...unit.replace(/\D/g, "")]
  const handleRemove = e => {
    e.stopPropagation()
    e.preventDefault()
    toggleProtoFaves(apartment)
  }

  return (
    <RoomCardWrapper>
      <UnitContainer style={{ width: isMini ? "100%" : "" }}>
        {chars.map((v, i) => (
          <ValueHolder
            key={i}
            hasBorder={i === chars.length - 1 ? null : "black solid 2px"}>
            {v}
          </ValueHolder>
        ))}
      </UnitContainer>
      {!isMini && (
        <>
          <ContentWrapper>
            <ButtonWrapper>
              <ButtonHolder style={{ gridColumn: 1 }}>
                <IsFaveIcon activeApartment={apartment} />
              </ButtonHolder>
              <ButtonHolder style={{ gridColumn: 2 }}>
                <CloseWrapper onClick={e => handleRemove(e)}>
                  <CloseButton height="80%" src={CloseIcon} />
                </CloseWrapper>
              </ButtonHolder>
            </ButtonWrapper>
            <TitleContainer>
              <BedsContainer colour={roomColours[beds]}>
                {BEDS_NAMED[beds].toUpperCase()}
              </BedsContainer>
              <FloorContainer>
                {FLOORS[floor].toUpperCase()} FLOOR
              </FloorContainer>
            </TitleContainer>
          </ContentWrapper>
        </>
      )}
    </RoomCardWrapper>
  )
}

const CloseWrapper = styled.div`
  padding: 1px;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
`

const CloseButton = styled.img`
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(0, -50%);
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  justify-content: flex-end;

  margin: 0.75vh;
  width: 100px;
  height: 15%;
  gap: 1;
  /* border: 1px solid black; */
`
const ButtonHolder = styled.div`
  position: relative;
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const BedsContainer = styled.div`
  font-family: GT-Sectra-Light;
  font-size: 100%;
  letter-spacing: 8px;
  color: ${props => props.colour};
  text-align: right;
`
const FloorContainer = styled.div`
  font-family: Atlas-Grotesk-Regular;
  font-size: 1.25vh;
  letter-spacing: 8px;
  text-align: right;
`

const ValueHolder = styled.div`
  min-width: 2vw;
  box-sizing: border-box;
  font-family: Circular-Std;
  flex: 1;
  border-right: black solid 2px;
  border-bottom: ${props => props.hasBorder};
  font-size: 1.45vh;
  line-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0px;
`

const UnitContainer = styled.div`
  flex-shrink: 1;
  display: flex;

  flex-direction: column;
`

const TitleContainer = styled.div`
  /* border: orange solid 1px; */
  flex: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: space-between;
  padding: 0.75rem;
`

const RoomCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border: black solid 2px;
`

const StyledCardContainer = styled.div`
  pointer-events: initial;
  cursor: pointer;
  box-sizing: border-box;
  font-family: Futura-Book;
  letter-spacing: 0.4em;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledMiniCardContainer = styled.div`
  position: absolute;
  background: ${props => props.colour}${props => props.opac};
  z-index: 9999999999999999999999999999999;
  pointer-events: initial;
  cursor: pointer;
  height: 100%;
  width: 100%;
  font-size: 10px;
  color: black;
`
