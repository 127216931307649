import Park151 from "pages/Park151"
import React from "react"
import Page404 from "pages/ErrorPages/Page404"

// Show Home
const ShowHome = React.lazy(() => import("./pages/ShowHome"))

// About
// const About = React.lazy(() => import("./pages/About"))

// Amenities
const Amenities = React.lazy(() => import("./pages/Amenities"))

// Gallery
const Gallery = React.lazy(() => import("./pages/Gallery"))

// Intro
const Intro = React.lazy(() => import("./pages/Intro"))

// Maps
const Maps = React.lazy(() => import("./pages/Maps"))

// Share
const Share = React.lazy(() => import("./pages/Share"))

const routes = [
  {
    path: "/",
    exact: true,
    name: "Intro",
    element: Intro,
    isOnNavbar: false
  },
  {
    path: "/park151",
    exact: true,
    name: "Park 151",
    element: Park151,
    isOnNavbar: true
  },

  {
    path: "/amenities",
    exact: true,
    name: "Amenities",
    element: Amenities,
    isOnNavbar: true
  },
  {
    path: "/gallery",
    exact: true,
    name: "Gallery",
    element: Gallery,
    isOnNavbar: true
  },
  { path: "/maps", exact: true, name: "Maps", element: Maps, isOnNavbar: true },
  {
    path: "/showhome",
    exact: true,
    name: "Show Home",
    element: ShowHome,
    isOnNavbar: true
  },
  {
    path: "/share",
    exact: true,
    name: "Share",
    element: Share,
    isOnNavbar: false
  },
  //   { path: "/about", exact: true, name: "About", element: About },
  { path: "/*", name: "Not Found", element: Page404 }
]

export default routes
