import HeartOn from "assets/icons/favourite-on.svg"
import HeartOff from "assets/icons/favourite-off.svg"
// import FloorPlanIcon from "assets/svg/floorplan-icon.svg"
import { useStore } from "store"

import styled from "styled-components"
import { useTransition, animated as a } from "@react-spring/web"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { useEffect, useState } from "react"

function IsFaveIcon({ activeApartment, onClick }) {
  const favourites = useStore(s => s.favourites)
  const toggleFavourites = useStore(s => s.toggleFavourites)

  const [isFave, setIsFave] = useState(
    favourites.some(
      e =>
        e[APARTMENT_FIELDS.unitCode] ===
        activeApartment[APARTMENT_FIELDS.unitCode]
    )
  )

  useEffect(() => {
    setIsFave(
      favourites.some(
        e =>
          e[APARTMENT_FIELDS.unitCode] ===
          activeApartment[APARTMENT_FIELDS.unitCode]
      )
    )
  }, [favourites, activeApartment])
  const transitions = useTransition(isFave, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },

    delay: 0
  })

  return (
    <Holder>
      {transitions((style, item) =>
        item ? (
          <MarkerHolder
            onClick={e => {
              console.log("object")
              e.stopPropagation()
              e.preventDefault()
              toggleFavourites(activeApartment)
            }}
            style={style}
            height="100%"
            src={HeartOn}
          />
        ) : (
          <MarkerHolder
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              toggleFavourites(activeApartment)
            }}
            style={style}
            height="100%"
            src={HeartOff}
          />
        )
      )}
    </Holder>
  )
}

export default IsFaveIcon

const Holder = styled.div`
  /* border: red solid 1px; */
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
`

const MarkerHolder = styled(a.img)`
  position: absolute;
  right: 0%;
  /* transform: translate(-50%, 0); */
`
