import Intro from "pages/Intro"
import React, { Suspense, useEffect, useRef } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import routes from "../routes"

function AppContent() {
  const location = useLocation()
  const prevLocation = useRef()

  useEffect(() => {
    prevLocation.current = location.pathname
  }, [location.pathname])

  return (
    <Suspense fallback={"LOADING..."}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element prevLocation={prevLocation.current} />}
              />
            )
          )
        })}
        <Route path="/" element={<Intro />} />
      </Routes>
    </Suspense>
  )
}

export default React.memo(AppContent)
