import IconButton from "components/Buttons/IconButton"
import styled from "styled-components"
import MenuIcon from "assets/icons/favourites-page-button.svg"
import { useStore } from "store"

export default function FavouritesBtn() {
  const finderVisible = useStore(state => state.finderVisible)
  const setFavouritesVisible = useStore(state => state.setFavouritesVisible)

  const handleClick = () => {
    setFavouritesVisible(true)
  }

  return (
    <>{finderVisible && <Button icon={MenuIcon} onClick={handleClick} />}</>
  )
}

const Button = styled(IconButton)`
  position: absolute;
  bottom: 0.2em;
  right: 3.5em;
  width: 4vh;
  height: 4vh;
  padding: 0;
  transition: all 0.2s ease-out;
  &:hover {
    opacity: 0.6;
  }
`
