export const apartmentsStatic = [
  {
    "unit #": "201",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "403",
    "Building Name": "Billington Mansions",
    "Address 1": "29 Sleaford Street",
    City: "London",
    Postcode: "SW8 5EY",
    Aspect: "South East",
    Price: "2925",
    Address: "North First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "202",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "413",
    "Building Name": "Billington Mansions",
    "Address 1": "29 Sleaford Street",
    City: "London",
    Postcode: "SW8 5EY",
    Aspect: "South East",
    Price: "2925",
    Address: "North First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "203",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S04",
    SqFt: "468",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1164",
    Address: "North First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "204",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S04",
    SqFt: "468",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3365",
    Address: "North First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "205",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S04",
    SqFt: "468",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3315",
    Address: "North First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "206",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S04",
    SqFt: "468",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3315",
    Address: "North First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "207",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S04",
    SqFt: "462",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1164",
    Address: "North First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "208",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "C19",
    SqFt: "1158",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6305",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "209",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "A11",
    SqFt: "824",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3935",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "210",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S14",
    SqFt: "668",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3415",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "211",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S08",
    SqFt: "593",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "212",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S09a",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1164",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "213",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S08",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "214",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S08",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "215",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S08",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "216",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S11",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "217",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S10",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "218",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S08",
    SqFt: "595",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3380",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "219",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S07",
    SqFt: "551",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3370",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "220",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "A07",
    SqFt: "691",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "West",
    Price: "3790",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "221",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S13",
    SqFt: "617",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "West",
    Price: "3290",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "222",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "A10",
    SqFt: "790",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "West",
    Price: "3905",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "223",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "A09",
    SqFt: "750",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3865",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "224",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S05",
    SqFt: "509",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3390",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "225",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "226",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1164",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "227",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "228",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "229",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "230",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "231",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "232",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "233",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3365",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "234",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S06",
    SqFt: "534",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3405",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "235",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "C10",
    SqFt: "970",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5385",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "236",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S12",
    SqFt: "599",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3380",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "237",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "2",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "413",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2925",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "303",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "641",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3855",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "304",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "602",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3815",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "305",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "306",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "307",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "5115",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "308",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "309",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "310",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "311",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "B01",
    SqFt: "853",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4320",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "312",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "C11",
    SqFt: "1034",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5395",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "313",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "314",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "315",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "316",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "3",
    Apt: "",
    "Flat Type": "C02",
    SqFt: "849",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4440",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "401",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "923",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4915",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "402",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6180",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "403",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3705",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "404",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3665",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "405",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2870",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "406",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1124",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "407",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5015",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "408",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5015",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "409",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "410",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "411",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "412",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "413",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3695",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "414",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "415",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "416",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "417",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2870",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "418",
    "Size (Bed/Person)": "2",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C01",
    SqFt: "761",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4215",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "419",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "704",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1173",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "420",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5095",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "421",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5095",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "422",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2870",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "423",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2870",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "424",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "D02",
    SqFt: "1146",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "6140",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "425",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "405",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "426",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2870",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "427",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "S02",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "428",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "597",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3655",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "429",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "4",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "580",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3635",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "501",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "928",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5035",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "502",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1696",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "503",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1173",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "504",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3785",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "505",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2890",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "506",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2890",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "507",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5135",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "508",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5035",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "509",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S02",
    SqFt: "425",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "510",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "511",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "512",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "513",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3715",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "514",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "515",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "516",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "517",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "518",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4860",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "519",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6060",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "520",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5115",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "521",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5215",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "522",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "523",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "524",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5015",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "525",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "526",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "527",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "528",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3775",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "529",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "5",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "577",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3655",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "601",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "928",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4935",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "602",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1696",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "603",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3725",
    Address: "N First Street",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "604",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3685",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "605",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2890",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "606",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2930",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "607",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5035",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "608",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5035",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "609",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "610",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "611",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "612",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "613",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3715",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "614",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "615",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "616",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "617",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2890",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "618",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4860",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "619",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "620",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5115",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "621",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5115",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "622",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "623",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "624",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4915",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "625",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "626",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "627",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2890",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "628",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3675",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "629",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "6",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "577",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3655",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "701",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "928",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5055",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "702",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6300",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "703",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3795",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "704",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3855",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "705",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2910",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "706",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2910",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "707",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "1452",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "708",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5055",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "709",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "710",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "711",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "712",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "713",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3735",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "714",
    "Size (Bed/Person)": "0",
    Bath: "0",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "715",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "716",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "717",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "718",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4880",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "719",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6080",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "720",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5135",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "721",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5235",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "722",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "723",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "724",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5035",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "725",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "726",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "727",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "728",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3795",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "729",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "7",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "577",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1173",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "801",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "928",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4955",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "802",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6200",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "803",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3795",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "804",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3755",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "805",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2910",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "806",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1124",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "807",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5055",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "808",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "809",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "810",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "811",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "812",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "813",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3735",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "814",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "815",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "816",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "817",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2910",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "818",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4880",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "819",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6080",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "820",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5135",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "821",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5135",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "822",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "823",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "10",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "824",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4935",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "825",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2910",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "826",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "827",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "S02",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "828",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3695",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "829",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "8",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "577",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3675",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "901",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1452",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "902",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6320",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "903",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "636",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3815",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "904",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "601",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1173",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "905",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2930",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "906",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2930",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "907",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5175",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "908",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5075",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "909",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "910",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "911",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "912",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "913",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3755",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "914",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "915",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "916",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "917",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "918",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4900",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "919",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6100",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "920",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5155",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "921",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5255",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "922",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "923",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "924",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5055",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "925",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "926",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "927",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "928",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3855",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "929",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "9",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "571",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3695",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1001",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "928",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4975",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1002",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1165",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1696",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1003",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3815",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1004",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3775",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1005",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2930",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1006",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1007",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5075",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1008",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5075",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1009",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1010",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1011",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1012",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1013",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3755",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1014",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1015",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1016",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1017",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2930",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1018",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C04",
    SqFt: "901",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4900",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1019",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "D01",
    SqFt: "1053",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6100",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1020",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5155",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1021",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C09",
    SqFt: "962",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5175",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1022",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1023",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1024",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "C07",
    SqFt: "939",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4955",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1025",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1026",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1027",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2930",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1028",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3715",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1029",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "10",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "577",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3695",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1101",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5095",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1102",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6340",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1103",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3835",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1104",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3895",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1105",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2950",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1106",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2950",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1107",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "1452",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1108",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5095",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1109",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1110",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1111",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1112",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "425",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1113",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3775",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1114",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1115",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1116",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1117",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1118",
    "Size (Bed/Person)": "2",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "C03",
    SqFt: "864",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4320",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1119",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "685",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "3965",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1120",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1121",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1122",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1123",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3835",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1124",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "11",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3715",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1201",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4995",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1202",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6240",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1203",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1173",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1204",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3795",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1205",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2950",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1206",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2950",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1207",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5115",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1208",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5095",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1209",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1210",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1211",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1212",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1213",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3815",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1214",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1215",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1216",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1217",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1218",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1219",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3865",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1220",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1221",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1222",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1223",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3735",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1224",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "12",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3715",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1301",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5115",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1302",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6360",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1303",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3855",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1304",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3915",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1305",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1306",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1307",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5215",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1308",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1309",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S02",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3010",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1310",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1311",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1312",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1313",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3795",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1314",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1315",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1316",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1317",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1318",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1319",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3985",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1320",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2970",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1321",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2970",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1322",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2970",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1323",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3855",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1324",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "13",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3735",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1401",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5015",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1402",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "D05",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6280",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1403",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3855",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1404",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3815",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1405",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2970",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1406",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3010",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1407",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "1452",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1408",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5115",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1409",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1410",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1411",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1412",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1413",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3795",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1414",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1415",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1416",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1417",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1418",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "4990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1419",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3885",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1420",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1124",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1421",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2970",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1422",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2970",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1423",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3755",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1424",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "14",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "1173",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1501",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5135",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1502",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6380",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1503",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3915",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1504",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3935",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1505",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2990",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1506",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2990",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1507",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5235",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1508",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1509",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1510",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1511",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1512",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1513",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1173",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1514",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1515",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1516",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1517",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1518",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5010",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1519",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4005",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1520",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2990",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1521",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2990",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1522",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2990",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1523",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3875",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1524",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "15",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3755",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1601",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5035",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1602",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1696",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1603",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3875",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1604",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3835",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1605",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "2990",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1606",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1124",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1607",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5135",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1608",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5155",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1609",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1610",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1611",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1612",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1613",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1173",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1614",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1615",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1616",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1617",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "2990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1618",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1619",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3905",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1620",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2990",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1621",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "2990",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1622",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "S02",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3030",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1623",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3775",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1624",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "16",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3755",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1701",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5155",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1702",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "6500",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1703",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1173",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1704",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "4055",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1705",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "1124",
    Address: "N First Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1706",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3110",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1707",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "5355",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1708",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1452",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1709",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1710",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1711",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3010",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1712",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1713",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3935",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1714",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1715",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1716",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1717",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1718",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5130",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1719",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4125",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1720",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3110",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1721",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3110",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1722",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3110",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1723",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4035",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1724",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "17",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3875",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1801",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "C05",
    SqFt: "922",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5995",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1802",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "D04",
    SqFt: "1166",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "7240",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1803",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A05",
    SqFt: "646",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "4835",
    Address: "N First Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "1804",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A03",
    SqFt: "607",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "4795",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1805",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3950",
    Address: "N First Street",
    Availability: "1",
    offset: "-90",
    flip: "0"
  },
  {
    "unit #": "1806",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "3950",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1807",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South East",
    Price: "1452",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1808",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "C08",
    SqFt: "958",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6095",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "1"
  },
  {
    "unit #": "1809",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1810",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1811",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3950",
    Address: "Water Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "1812",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "426",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3850",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1813",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A04",
    SqFt: "635",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1173",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1814",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1815",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S03",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3990",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1816",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "3950",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1817",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "1124",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1818",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "C06",
    SqFt: "940",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "5970",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1819",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A06",
    SqFt: "676",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4865",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1820",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "406",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1821",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1822",
    "Size (Bed/Person)": "0",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "S01",
    SqFt: "427",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "3950",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "1823",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A02",
    SqFt: "598",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "4735",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "1824",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "18",
    Apt: "",
    "Flat Type": "A01",
    SqFt: "581",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5965",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH1901",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C16P",
    SqFt: "1293",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "7460",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "PH1902",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "D8P1",
    SqFt: "1574",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East South West",
    Price: "9125",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH1903",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C13P",
    SqFt: "1172",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "7090",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH1904",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "D6P1",
    SqFt: "1482",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "8975",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH1905",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "D7P1",
    SqFt: "1482",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "8825",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "PH1906",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C14P",
    SqFt: "1187",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6955",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "PH1907",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C12P",
    SqFt: "1084",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "6710",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH1908",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C17P",
    SqFt: "1302",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "7710",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH1909",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "D9P1",
    SqFt: "1632",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South West",
    Price: "9375",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "PH1910",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "A12P",
    SqFt: "824",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5170",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "PH1911",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "19",
    Apt: "",
    "Flat Type": "C15P",
    SqFt: "1282",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "7255",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "PH2001",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C16P",
    SqFt: "1293",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "7960",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "PH2002",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "D8P2",
    SqFt: "1574",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "9625",
    Address: "Corner of N First St and Morgan Ave",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH2003",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C13P",
    SqFt: "1172",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "7490",
    Address: "N First Street",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH2004",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "D6P2",
    SqFt: "1482",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South East",
    Price: "9425",
    Address: "Corner of Water Street and N First",
    Availability: "1",
    offset: "270",
    flip: "0"
  },
  {
    "unit #": "PH2005",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "D7P2",
    SqFt: "1482",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "9225",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "PH2006",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C14P",
    SqFt: "1187",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "7355",
    Address: "Water Street",
    Availability: "1",
    offset: "90",
    flip: "0"
  },
  {
    "unit #": "PH2007",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C12P",
    SqFt: "1084",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "7110",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH2008",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C18P",
    SqFt: "1302",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North",
    Price: "7940",
    Address: "Water Street",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH2009",
    "Size (Bed/Person)": "3",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "D9P2",
    SqFt: "1632",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "North South West ",
    Price: "9775",
    Address: "Corner of Morgan Ave and Water St",
    Availability: "1",
    offset: "0",
    flip: "0"
  },
  {
    "unit #": "PH2010",
    "Size (Bed/Person)": "1",
    Bath: "1",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "A12P",
    SqFt: "824",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "5670",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  },
  {
    "unit #": "PH2011",
    "Size (Bed/Person)": "2",
    Bath: "2",
    Building: "",
    Level: "20",
    Apt: "",
    "Flat Type": "C15P",
    SqFt: "1282",
    "Building Name": "",
    "Address 1": "",
    City: "",
    Postcode: "",
    Aspect: "South West",
    Price: "7655",
    Address: "Morgan Avenue",
    Availability: "1",
    offset: "180",
    flip: "0"
  }
]
