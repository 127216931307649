import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { offsetArr } from "components/ApartmentFinder/config/helperFunctions"
import { useStore } from "store"
import styled from "styled-components"

export function Scroll3DApt() {
  const activeType = useStore(state => state.activeType)
  const activeApartment = useStore(state => state.activeApartment)
  const viewOpenID = useStore(state => state.viewOpenID)
  const protoFaves = useStore(state => state.protoFaves)
  const setActiveApartment = useStore(state => state.setActiveApartment)

  const activeIndex = protoFaves.findIndex(
    i =>
      i?.[APARTMENT_FIELDS.unitCode] ===
      activeApartment?.[APARTMENT_FIELDS.unitCode]
  )

  const handleRight = () => {
    setActiveApartment(protoFaves[offsetArr(protoFaves, activeIndex, 1)])
  }
  const handleLeft = () => {
    setActiveApartment(protoFaves[offsetArr(protoFaves, activeIndex, -1)])
  }

  return (
    <>
      {!viewOpenID && activeType && activeApartment && protoFaves.length > 1 && (
        <>
          <Arrow
            onClick={() => {
              handleRight()
            }}
            style={{
              right: "0"
            }}>
            {">>"}
          </Arrow>
          <Arrow
            onClick={() => {
              handleLeft()
            }}
            style={{ left: 0 }}>
            {"<<"}
          </Arrow>
        </>
      )}
    </>
  )
}

const Arrow = styled.div`
  z-index: 25;
  opacity: 0.3;
  align-items: center;
  cursor: pointer;
  position: absolute;
  margin: 30px;
  bottom: 50%;
  z-index: 20;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: space-around;
  letter-spacing: 0.2rem;
  font-size: 2.7rem;
`
