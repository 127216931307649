import styled from "styled-components"
import theme from "theme/theme"
import { filteringIndex } from "../../config/filterConfig"
import { useEffect, useRef, useState } from "react"
import { useTransition, config } from "@react-spring/core"
import { animated as a } from "@react-spring/web"
import { useStore } from "store"
import { useLongPress, useOnClickOutside } from "utils/hooks"
import { BackExit } from "../apartments/ApartmentsLayout"

function InteractionContainer({ callback }) {
  const interactionBoxActive = useStore(state => state.interactionBoxActive)

  const ref = useRef()

  useOnClickOutside(ref, () => {
    interactionBoxActive && callback(null)
  })

  const transition = useTransition(interactionBoxActive, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 }
  })

  return transition(
    (style, item) =>
      item && (
        <TempInteractionHolder style={style} ref={ref}>
          {interactionBoxActive?.component}
        </TempInteractionHolder>
      )
  )
}

function Filtering({ showFiltersBar }) {
  const setInteractionBoxActive = useStore(
    state => state.setInteractionBoxActive
  )

  const filterVisible = useStore(state => state.filterVisible)

  const transition = useTransition(filterVisible, {
    from: { opacity: 0.0 },
    enter: { opacity: 1 },
    leave: { opacity: 0.0 },
    config: { clamp: true, ...config.wobbly }
  })

  return (
    <>
      {transition(
        (style, item) =>
          item && (
            <ButtonsContainer>
              {showFiltersBar ? (
                <FilterBar
                  style={{
                    ...style,
                    pointerEvents: filterVisible ? "auto" : "none"
                  }}>
                  <InteractionContainer callback={setInteractionBoxActive} />
                  <FilterButtons />
                </FilterBar>
              ) : (
                <BackExit />
              )}
            </ButtonsContainer>
          )
      )}
    </>
  )
}

function FilterButtons() {
  const minPrice = useStore(state => state.minPrice)
  const maxPrice = useStore(state => state.maxPrice)
  const minLevel = useStore(state => state.minLevel)
  const maxLevel = useStore(state => state.maxLevel)
  const resetFilters = useStore(state => state.resetFilters)

  const resetProtoFaves = useStore(state => state.resetProtoFaves)
  const setActiveType = useStore(state => state.setActiveType)
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const interactionBoxActive = useStore(state => state.interactionBoxActive)

  const [items] = useState(Object.values(filteringIndex))

  const setInteractionBoxActive = useStore(
    state => state.setInteractionBoxActive
  )

  useEffect(() => {
    return () => {
      setInteractionBoxActive(null)
    }
  }, [setInteractionBoxActive])

  const handleClick = v => {
    setInteractionBoxActive(v)
    if (v.action === interactionBoxActive?.action) {
      setInteractionBoxActive(null)
      return
    }
  }

  const transitions = useTransition(items, {
    from: { opacity: 0 },
    enter: item => next => next({ opacity: 1, delay: item.delay }),
    leave: { opacity: 0 }
  })

  const onLongPress = e => {
    console.dir(e.target)
    if (e.target.id === "clear") {
      resetProtoFaves()
      setActiveType(null)
      setActiveApartment(null)
    }
  }

  const handleClear = e => {
    setInteractionBoxActive(null)
    resetFilters({
      priceRange: [minPrice, maxPrice],
      level: [minLevel, maxLevel]
    })
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500
  }

  const longPressEvent = useLongPress(onLongPress, handleClear, defaultOptions)

  return transitions(
    (style, item, i) =>
      item &&
      (item.action !== "clear" ? (
        <ButtonContainer
          id={item.action === "clear" ? "clear" : "ignore"}
          test={2}
          title={""}
          onClick={() => handleClick(item)}
          style={{
            backgroundColor:
              interactionBoxActive?.action === item.action ? "#F87144cc" : "",
            border: "black solid 1px",

            ...style
          }}
          img={item.icon}></ButtonContainer>
      ) : (
        <ButtonContainer
          {...longPressEvent}
          id={item.action === "clear" ? "clear" : "ignore"}
          test={2}
          title={""}
          style={{
            ...style
          }}
          img={item.icon}></ButtonContainer>
      ))
  )
}

const ButtonContainer = styled(a.div)`
  cursor: pointer;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  height: 70%;
  display: flex;
  aspect-ratio: 1 / 1;
  justify-content: center;
  align-items: center;
  min-width: 4vh;
  min-height: 4vh;
  padding: 0.5rem;
  transition: background-color ease 0.3s;
`

const TempInteractionHolder = styled(a.div)`
  position: absolute;
  color: white;
  bottom: calc(5.2vh);
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.finderBgColor};
  /* border-radius: 3rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #030201;
`

const ButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 1.75rem;
  height: 5vh;
  bottom: 0.1em;
  background-color: ${theme.colors.finderBgColor};
`

const FilterBar = styled(a.div)`
  padding: 4px 0px;
  position: absolute;
  width: 13em;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  z-index: 99;
`

export default Filtering
