export const BEDS = {
  0: "STUDIO",
  1: "1",
  2: "2",
  3: "3"
}
export const BEDS_NAMED = {
  0: "STUDIO",
  1: "ONE BED",
  2: "TWO BED",
  3: "THREE BED"
}

export const BED_COLORS = {
  0: "#D1F4C7",
  1: "#4A5417",
  2: "#D79E7C",
  3: "#EF774D"
}

export const FLOORS = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
  7: "seventh",
  8: "eighth",
  9: "ninth",
  10: "tenth",
  11: "eleventh",
  12: "twelfth",
  13: "thirteenth",
  14: "fourteenth",
  15: "fifteenth",
  16: "sixteenth",
  17: "seventeenth",
  18: "eighteenth",
  19: "nineteenth",
  20: "twentieth",
  21: "twenty-first",
  22: "twenty-second",
  23: "twenty-third",
  24: "twenty-fourth",
  25: "twenty-fifth",
  26: "twenty-sixth",
  27: "twenty-seventh",
  28: "twenty-eighth",
  29: "twenty-ninth",
  30: "thirtieth",
  31: "thirty-first"
}
