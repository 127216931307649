import React from "react"
import Navbar from "components/Navbar"
import AppContent from "components/AppContent"
import { useLocation } from "react-router-dom"
import ApartmentFinder from "pages/ApartmentFinder"
import Favourites from "pages/ApartmentFinder/components/Favourites"

export default function DefaultLayout() {
  const location = useLocation()

  return (
    <>
      {location.pathname === "/" || location.pathname === "/share" ? null : (
        <Navbar />
      )}
      <ApartmentFinder />
      <Favourites />
      <AppContent />
    </>
  )
}
