import React from "react"
import { Link } from "react-router-dom"
import EnterButton from "assets/svg/enter-button.svg"

export default function Page404() {
  return (
    <div style={{ background: "#F87144", height: "100%" }}>
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          top: "50%"
        }}>
        This page doesn't exist. Click the button to go back home.
        <Link to="/">
          <img style={{ paddingTop: "50px" }} src={EnterButton} alt="" />
        </Link>
      </div>
    </div>
  )
}
