const marginVertical = "2rem"
const mainWidth = "55vw"
const borderRadius = "0.2rem"

export const apartmentFinderTheme = {
  splitPercentage: 56,
  // splitPercentage: 48,
  marginVertical: marginVertical,
  mainWidth: mainWidth,
  borderRadius: borderRadius
}
