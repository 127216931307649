import { APARTMENT_FIELDS } from "./fieldMappers"

export const returnInfo = (id, info) => {
  if (info === "isHanded") {
    return id.split("-")[1]
  } else if (info === "name") {
    return id.split("-")[0]
  }
}

export const returnPostAddress = data => {
  let number = data[APARTMENT_FIELDS.number]
  let building = data[APARTMENT_FIELDS.building]

  return number + " " + building
}

export function offsetArr(array, index, n) {
  return (((index + n) % array.length) + array.length) % array.length
}
