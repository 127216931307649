export const titles = [
  "APT",
  "TYPE",
  "MONTHLY",
  "BEDS",
  "FACING",
  "FLOOR",
  ""
  // "ENT"
]

export const flexArr = [2, 2, 3, 3, 2, 1, 1]
