import { useState, useEffect } from "react"

export default function useCheckInternetConnection() {
  const [isConnected, setIsConnected] = useState(null)

  useEffect(() => {
    var condition = navigator.onLine ? "online" : "offline"

    if (condition === "online") {
      fetch("https://www.bing.com/", {
        // Check for internet connectivity
        mode: "no-cors"
      })
        .then(() => {
          setIsConnected("online")
        })
        .catch(() => {
          setIsConnected("offline")
        })
    } else {
      setIsConnected("offline")
    }
  })

  return { isConnected }
}
