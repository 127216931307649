import create from "zustand"

import { filteringIndex } from "components/ApartmentFinder/config/filterConfig"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"

export const useStore = create((set, get) => ({
  isCube: false,
  setIsCube: v => set({ isCube: v }),
  apartmentIndex: {},
  setApartmentIndex: v => set({ apartmentIndex: v }),
  ///////////////////////////////
  //Navbar
  ///////////////////////////////

  interactionBoxActive: null,
  setInteractionBoxActive: v => set({ interactionBoxActive: v }),
  ///////////////////////////////
  //Amenities
  ///////////////////////////////

  cameraPosition: {
    positionX: -87.51527778122605,
    positionY: 256.5161517536913,
    positionZ: 242.93058208460053,
    targetX: -14.0292995,
    targetY: -30.79905649999999,
    targetZ: -85.8076839
  },

  setAnimateCam: v =>
    set({
      cameraPosition: v
    }),

  currentAmenityIndex: "rest",
  setCurrentAmenityIndex: v => set({ currentAmenityIndex: v }),
  meshCenter: [0, 0, 0],
  setMeshCenter: v => set({ meshCenter: v }),
  ///////////////////////////////
  //About
  ///////////////////////////////
  openAboutDialog: false,
  setOpenAboutDialog: v => set({ openAboutDialog: v }),
  ///////////////////////////////
  //Maps
  ///////////////////////////////

  currentTab: 0,
  setCurrentTab: v => set({ currentTab: v }),

  ///////////////////////////////
  //Apartment Finder
  ///////////////////////////////
  apiApartments: [],
  setApiApartments: v => set({ apiApartments: v }),
  filteredIndex: null,
  setFilteredIndex: v => set({ filteredIndex: v }),
  ///////////////////////////////
  floorplanDialogue: false,
  setFloorplanDialogue: v => set({ floorplanDialogue: v }),
  viewOpenID: false,
  setViewOpenID: v => set({ viewOpenID: v }),

  allApartmentStateLoaded: false,
  setAllApartmentStateLoaded: v => set({ allApartmentStateLoaded: v }),
  apartmentSceneLoaded: false,
  setApartmentSceneLoaded: v => set({ apartmentSceneLoaded: v }),
  buildingSceneLoaded: false,
  setBuildingSceneLoaded: v => set({ buildingSceneLoaded: v }),
  finderVisible: false,
  setFinderVisible: v => set({ finderVisible: v }),
  filterVisible: false,
  setFilterVisible: v => set({ filterVisible: v }),
  loading: false,
  setLoading: v => set({ loading: v }),
  openFavorite: false,
  setOpenFavorite: v => set({ openFavorite: v }),
  viewTitle: false,
  setViewTitle: v => set({ viewTitle: v }),
  cameraRotation: 0,
  setCameraRotation: v => set({ cameraRotation: v }),
  //Additional Content
  showPlan: false,
  setShowPlan: v => set({ showPlan: v }),
  showSpec: false,
  setShowSpec: v => set({ showSpec: v }),
  showViews: false,
  setShowViews: v => set({ showViews: v }),

  //Apartment finder
  compassRotation: 90,
  setCompassRotation: v => set({ compassRotation: v }),

  // typeIndex: null,
  protoFaves: [],

  resetProtoFaves: v => set({ protoFaves: [] }),

  toggleProtoFaves: apartment =>
    set(state => ({
      protoFaves: state.protoFaves.some(
        e =>
          e[APARTMENT_FIELDS.unitCode] === apartment[APARTMENT_FIELDS.unitCode]
      )
        ? state.protoFaves.filter(
            c =>
              apartment[APARTMENT_FIELDS.unitCode] !==
              c[APARTMENT_FIELDS.unitCode]
          )
        : [apartment, ...state.protoFaves]
    })),

  viewingSimilar: false,
  setViewingSimilar: v => set({ viewingSimilar: v }),

  viewingNew: false,
  setViewingNew: v => set({ viewingNew: v }),

  previouslyViewed: false,
  setPreviouslyViewed: v => set({ previouslyViewed: v }),

  typeIndex: null,
  setTypeIndex: v => set({ typeIndex: v }),

  activeFloor: null,
  setActiveFloor: v => set({ activeFloor: v }),

  activeApartment: null,
  // activeApartment: {
  //   "unit #": "206",
  //   "Size (Bed/Person)": "0",
  //   Bath: "1",
  //   Building: "",
  //   Level: "2",
  //   Apt: "",
  //   "Flat Type": "S04",
  //   SqFt: "468",
  //   "Building Name": "",
  //   "Address 1": "",
  //   City: "",
  //   Postcode: "",
  //   Aspect: "South East",
  //   Price: "3315",
  //   Address: "North First Street",
  //   Availability: "0",
  //   x: "0px",
  //   y: "0px",
  //   width: 590.6328125,
  //   height: 315.9296875,
  //   index: 0
  // },
  setActiveApartment: v => set({ activeApartment: v }),

  activeType: null,
  setActiveType: v => set({ activeType: v }),

  activeTypeArr: null,
  setActiveTypeArr: v => set({ activeTypeArr: v }),

  handedActive: "0",
  setHandedActive: v => set({ handedActive: v }),

  tempLive: [],
  setTempLive: v => set({ tempLive: v }),

  tempExistingModels: [],
  setTempExistingModels: v => set({ tempExistingModels: v }),

  apartments: [],
  setApartments: v => set({ apartments: v }),

  // Filtering
  priceRange: [filteringIndex.price.min, filteringIndex.price.max],
  setPriceRange: v => set({ priceRange: v }),

  minPrice: 0,
  setMinPrice: v => set({ minPrice: v }),
  maxPrice: 0,
  setMaxPrice: v => set({ maxPrice: v }),

  monthlyCostRange: [500, 3500],
  setMonthyCostRange: v => set({ monthlyCostRange: v }),

  minLevel: 0,
  setMinLevel: v => set({ minLevel: v }),
  maxLevel: 0,
  setMaxLevel: v => set({ maxLevel: v }),

  resetFilters: v =>
    set({
      priceRange: v.priceRange,
      level: v.level,
      bedroomCounts: [],
      aspects: []
    }),
  level: [0, 0],
  setLevelRange: v => set({ level: v }),

  bedroomCounts: [],
  toggleBedroomCounts: count =>
    set(state => ({
      bedroomCounts: count.length
        ? state.bedroomCounts.includes(count)
          ? state.bedroomCounts.filter(c => count !== c)
          : [...state.bedroomCounts, count]
        : []
    })),
  aspects: [],
  toggleAspects: count =>
    set(state => ({
      aspects: count.length
        ? state.aspects.includes(count)
          ? state.aspects.filter(c => count !== c)
          : [...state.aspects, count]
        : []
    })),
  floorCounts: [],
  toggleFloorCounts: count =>
    set(state => ({
      floorCounts: state.floorCounts.includes(count)
        ? state.floorCounts.filter(c => count !== c)
        : [...state.floorCounts, count]
    })),

  apartmentPositions: null,
  setApartmentPositions: v => set({ apartmentPositions: v }),

  cameraPositions: [],
  setCameraPositions: v =>
    set(state => ({ cameraPositions: [...state.cameraPositions, v] })),
  cameraPosition: [100, 900, 100],

  ///////////////////////////////
  //Favourites
  ///////////////////////////////
  favouritesVisible: false,
  setFavouritesVisible: v => set({ favouritesVisible: v }),
  openCompareDialog: false,
  setOpenCompareDialog: v => set({ openCompareDialog: v }),
  favourites: [],
  // favourites: [],
  toggleFavourites: apartment =>
    set(state => ({
      favourites: state.favourites.some(
        e =>
          e[APARTMENT_FIELDS.unitCode] === apartment[APARTMENT_FIELDS.unitCode]
      )
        ? state.favourites.filter(
            c =>
              apartment[APARTMENT_FIELDS.unitCode] !==
              c[APARTMENT_FIELDS.unitCode]
          )
        : [...state.favourites, apartment]
    })),

  // toggleFavourites: apartment =>
  //   set(state => ({
  //     favourites: state.favourites.includes(apartment)
  //       ? state.favourites.filter(c => apartment !== c)
  //       : [...state.favourites, apartment]
  //   })),

  setFavourites: favourites => set(() => ({ favourites })),
  addFavourite: id => set(state => ({ favourites: [...state.favourites, id] })),
  setFavouritesActive: active => set({ favouritesActive: active }),
  favouritesActive: false,
  // removeFavourite: id =>
  //   set(state => ({
  //     favourites: state.favourites.filter(_id => _id !== id)
  //   })),
  firstDustbin: null,
  setFirstDustbin: v => set({ firstDustbin: v }),
  secondDustbin: null,
  setSecondDustbin: v => set({ secondDustbin: v })
}))
