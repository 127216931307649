import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"
import { useCallback, useEffect, useRef, useState } from "react"

const { useStore } = require("store")

export const useFilteredIndex = () => {
  const apartmentIndex = useStore(state => state.apartmentIndex)
  const level = useStore(state => state.level)

  const priceRange = useStore(state => state.priceRange)
  const bedroomCounts = useStore(state => state.bedroomCounts)
  const aspects = useStore(state => state.aspects)
  const filteredIndex = []
  Object.values(apartmentIndex).map(
    a =>
      (!bedroomCounts.length ||
        bedroomCounts.includes(a[APARTMENT_FIELDS.beds])) &&
      (!aspects.length ||
        aspects.some(e => a[APARTMENT_FIELDS.aspect].includes(e))) &&
      a[APARTMENT_FIELDS.price] >= priceRange[0] &&
      a[APARTMENT_FIELDS.price] <= priceRange[1] &&
      parseInt(a[APARTMENT_FIELDS.level]) >= level[0] &&
      parseInt(a[APARTMENT_FIELDS.level]) <= level[1] &&
      parseInt(a[APARTMENT_FIELDS.status]) === 0 &&
      filteredIndex.push(a[APARTMENT_FIELDS.unitCode])
  )
  return filteredIndex
}

export const useAvailableApartments = (all = false) => {
  // console.log("using")
  const apartments = useStore(state => state.apartments)
  const activeType = useStore(state => state.activeType)
  const activeApartment = useStore(state => state.activeApartment)
  // Filters
  const bedroomCounts = useStore(state => state.bedroomCounts)
  const aspects = useStore(state => state.aspects)
  const priceRange = useStore(state => state.priceRange)
  const level = useStore(state => state.level)

  let filteredApts

  if (activeApartment && !all) {
    filteredApts = apartments.filter(
      a =>
        a[APARTMENT_FIELDS.unitCode] ===
        activeApartment[APARTMENT_FIELDS.unitCode]
    )
  } else if (activeType && !all) {
    // return apartments.filter(a => a[APARTMENT_FIELDS.type] === activeType)
    // return apartments.filter(
    //   a =>
    //     a[APARTMENT_FIELDS.type].replace("H", "") === activeType.replace("H", "")
    // )
  } else {
    filteredApts = apartments.filter(
      a =>
        (!bedroomCounts.length ||
          bedroomCounts.includes(a[APARTMENT_FIELDS.beds])) &&
        (!aspects.length ||
          aspects.some(e => a[APARTMENT_FIELDS.aspect].includes(e))) &&
        a[APARTMENT_FIELDS.price] >= priceRange[0] &&
        a[APARTMENT_FIELDS.price] <= priceRange[1] &&
        parseInt(a[APARTMENT_FIELDS.level]) >= level[0] &&
        parseInt(a[APARTMENT_FIELDS.level]) <= level[1] &&
        parseInt(a[APARTMENT_FIELDS.status]) === 0
    )
  }

  return filteredApts
}

export const useAvailableApartmentsByTypes = (all = false) => {
  const availableApartments = useAvailableApartments(all)
  const data = {}
  const apartmentsByTypeObj = {}
  const apartmentsByPlotObj = {}

  availableApartments.map(
    ap => (apartmentsByTypeObj[ap[APARTMENT_FIELDS.type]] = ap)
  )
  availableApartments.map(
    ap => (apartmentsByPlotObj[ap[APARTMENT_FIELDS.unitCode]] = ap)
  )

  // Combine apartments that have a mirrored verison, AKA 'handed'
  // these will be mirrored in geometry instead
  // const removeHandedSet = availableApartments.filter(
  //   ap => !ap[APARTMENT_FIELDS.type].includes("H")
  // )

  const typeSet = new Set(
    availableApartments.map(ap => ap[APARTMENT_FIELDS.type])
  )

  // const typeSet = new Set(removeHandedSet.map(ap => ap[APARTMENT_FIELDS.type]))

  data.typeArr = Array.from(typeSet)
  data.apartmentsByType = apartmentsByTypeObj
  data.apartmentsByPlot = apartmentsByPlotObj

  return data
}

export const useToggleApartment = apartment => {
  const protoFaves = useStore(state => state.protoFaves)
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const activeType = useStore(state => state.activeType)
  const setActiveType = useStore(state => state.setActiveType)

  useEffect(() => {
    let tempArr = protoFaves.filter(
      e => e[APARTMENT_FIELDS.type] === activeType
    )

    if (tempArr.length === 0) {
      setActiveType(null)
      setActiveApartment(null)
    }
  })
}
export const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 300 } = {},
  callback
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false)
  const timeout = useRef()
  const target = useRef()

  const start = useCallback(
    event => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault]
  )

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick(event)
      setLongPressTriggered(false)
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  )

  return {
    onMouseDown: e => start(e),
    onTouchStart: e => start(e),
    onMouseUp: e => clear(e),
    onMouseLeave: e => clear(e, false),
    onTouchEnd: e => clear(e)
  }
}
const isTouchEvent = event => {
  return "touches" in event
}

const preventDefault = event => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}

export function useOnClickOutside(ref, handler, ignore) {
  useEffect(
    () => {
      const listener = event => {
        // console.log(event.target.id)
        // Do nothing if clicking ref's element or descendent elements
        if (
          !ref.current ||
          event.target.id === "ignore" ||
          ref.current.contains(event.target)
        ) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}
