import styled from "styled-components"
import { animated as a } from "@react-spring/web"

export default function IconButton(props) {
  const { onClick, className, icon } = props
  return (
    <Button {...props} className={className} onClick={onClick}>
      <img src={icon} alt=""></img>
    </Button>
  )
}

const Button = styled(a.button)`
  width: 3.5em;
  height: 3.5em;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.5em;
  transition: all 0.2s ease-out;
  border-radius: 50%;
  img {
    width: 100%;
  }
  &:hover {
    opacity: 0.6;
  }
`
