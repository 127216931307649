import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import Skyline from "assets/svg/skyline.svg"
import { colours } from "theme/colours"
import HorizontalLogo from "assets/svg/logo-horizontal.svg"
import { AnimatePresence, motion } from "framer-motion"
import { Swiper, SwiperSlide } from "swiper/react"
import "./Park151.css"

const cinemagraphs = [
  {
    className: "cinema1",
    url: `${process.env.PUBLIC_URL}/assets/videos/entrance.mp4`,
    caption:
      "INTRODUCING CAMBRIDGE CROSSING’S PREMIUM RESIDENCES CONVENIENTLY PERCHED ON THE PARK"
  },
  {
    className: "cinema2",
    url: `${process.env.PUBLIC_URL}/assets/videos/sky.mp4`,
    caption:
      "READY-TO-RENT STUDIOS, ONE, TWO & THREE BEDROOMS FOR LIVES OF ALL SIZES  "
  },
  {
    className: "cinema3",
    url: `${process.env.PUBLIC_URL}/assets/videos/pool.mp4`,
    caption: "THE BEST VIEWS IN TOWN AND PREMIUM AMENITIES AT YOUR DOORSTEP"
  }
]

const animationSettings = {
  transition: { opacity: 0, duration: 0.4 },
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 }
}

const captionSettings = {
  transition: { opacity: 0, duration: 0.5 },
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 }
}

export default function Park151({ prevLocation }) {
  const player = useRef()
  const [isPlaying, setIsPlaying] = useState(true)
  const logoRef = useRef()
  const morphRef = useRef()
  const [cinemaCaption, setCinemaCaption] = useState(cinemagraphs[0])

  return (
    <Holder>
      <LogoButton
        ref={logoRef}
        src={HorizontalLogo}
        alt=""
        style={prevLocation !== "/" ? { display: "none" } : {}}
      />
      <Morpher
        ref={morphRef}
        onAnimationEnd={() => {
          logoRef.current.classList.add("fade-out")
        }}
        className={prevLocation === "/" ? "opened" : ""}>
        <AnimatePresence>
          <ReactPlayerMotion {...animationSettings}>
            <SwipperWrapper
              onSlideChangeTransitionStart={() => {
                setIsPlaying(false)
                setCinemaCaption({})
              }}
              onSlideChangeTransitionEnd={e => {
                setCinemaCaption(cinemagraphs[e.activeIndex])
                setIsPlaying(true)
              }}
              spaceBetween={150}
              slidesPerView={1}
              preventInteractionOnTransition={false}
              centeredSlides={true}>
              {cinemagraphs.map((cinema, i) => {
                return (
                  <SwiperSlide key={cinema.url}>
                    <ReactPlayer
                      className="react-player"
                      ref={player}
                      url={cinema.url}
                      width="100%"
                      height="100%"
                      playsinline={true}
                      controls={false}
                      playing={isPlaying}
                      stopOnUnmount={true}
                      loop={true}
                      muted={true}
                    />
                  </SwiperSlide>
                )
              })}
              <CinemaCaption
                {...captionSettings}
                key={cinemaCaption.caption}
                className={cinemaCaption.className}>
                {cinemaCaption.caption}
              </CinemaCaption>
            </SwipperWrapper>
          </ReactPlayerMotion>
        </AnimatePresence>
      </Morpher>

      {/* <BackgroundIllustration src={Skyline} alt="skyline" /> */}
    </Holder>
  )
}

const SwipperWrapper = styled(Swiper)`
  height: 100%;
`

const ReactPlayerMotion = styled(motion.div)`
  height: 100%;
`

const Morpher = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 8;
  padding: 2em 2em 4em;
  background: #f87144;
  clip-path: inset(calc(50%) calc(40% - 20px) round 20px);
  clip-path: inset(0);

  &.opened {
    animation: morph 0.9s ease-in-out forwards;

    @keyframes morph {
      0%,
      90% {
        clip-path: inset(calc(45% + 10px) calc(40% - 20px) round 20px);
      }

      90%,
      100% {
        clip-path: inset(0);
      }
    }
  }
`

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  object-fit: cover;
  background-color: ${colours.secondary.sunset};
`

const CinemaCaption = styled(motion.div)`
  position: fixed;
  line-height: 1.2em;
  padding: 15px 40px;
  z-index: 9;
  font-family: "GT-Sectra-Light";
  color: #fff;
  font-size: 2.5vw;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
    1px 1px 1px rgba(0, 0, 0, 0.5), 2px 0px 20px rgba(184, 184, 184, 0.35);
`

const LogoButton = styled.img`
  position: absolute;
  max-height: 130px;
  margin: auto auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  &.fade-out {
    opacity: 0;
  }

  &.none {
    display: none;
  }
`
