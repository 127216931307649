import styled from "styled-components"
import { useStore } from "store"
import AnimatedCardList from "./AnimatedCardList"
import { AnimatedMiniList } from "./AnimatedCardList"
import { useRef } from "react"

import Instructions from "assets/svg/filter-instruction.svg"

export function CardLayout() {
  const activeApartment = useStore(state => state.activeApartment)

  const activeType = useStore(state => state.activeType)
  const protoFaves = useStore(state => state.protoFaves)

  const setViewTitle = useStore(state => state.setViewTitle)

  const ref = useRef()

  const handleScroll = e => {
    if (e.target?.scrollTop) {
      setViewTitle(false)
    } else setViewTitle(true)
  }

  return (
    <>
      {!activeApartment && !activeType && (
        <CardLayoutContainer
          ref={ref}
          onScroll={handleScroll}
          touch={protoFaves?.length <= 6 ? "none" : "auto"}>
          <AnimatedCardList />
          {protoFaves.length === 0 ? (
            <div
              style={{
                // border: "red solid 1px",
                height: "80%",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                alignItems: "center",

                position: "absolute",
                fontSize: "0.8em",
                textJustify: "center",
                letterSpacing: "0.3em",
                left: "50%",
                bottom: "0%",
                transform: "translate(-50%,0%)",
                width: "100%"
              }}>
              <div
              // style={{ border: "red solid 1px" }}
              >
                No Selected Apartments
                <br />
                <br />
                Please Select From List
              </div>
              <img
                alt=""
                style={
                  {
                    // position: "absolute",
                    // bottom: 0,
                    // border: "red solid 1px"
                  }
                }
                src={Instructions}
              />
              <p style={{fontSize: "0.7em", width: "80%", textAlign: "center", letterSpacing: "0.1em"}}>Renderings are an artist's conception and are intended only as a general reference. Features, materials, finishes and layout of subject unit may be different from that shown.</p>
            </div>
          ) : null}
        </CardLayoutContainer>
      )}
      {(activeApartment || activeType) && (
        <MiniCardLayoutContainer>
          <AnimatedMiniList />
        </MiniCardLayoutContainer>
      )}
    </>
  )
}

export const CardLayoutContainer = styled.div`
  position: absolute;
  touch-action: pan-y;
  z-index: 10;
  width: 100%;
  height: 100%;
  font-family: GT-Sectra-Light;
  overflow-y: auto;
`
export const MiniCardLayoutContainer = styled.div`
  position: absolute;
  display: flex;
  /* border: red solid 2px; */
  bottom: 0.75rem;
  left: 0.75rem;
  width: calc(100% - 2 * 0.75rem);
  /* height: 110px; */
  min-height: 8.5vh;
  touch-action: pan-x;
  z-index: 10;
  overflow-y: hidden;
  /* ::-webkit-scrollbar {
    display: none;
  } */
`
