import { Suspense, useMemo } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"

import { device } from "theme/device"
import getQueryString from "utils/getQueryString"
import { useStore } from "store"

import Loader from "components/Loader"
import ApartmentCard from "components/ApartmentCard"
import { APARTMENT_FIELDS } from "components/ApartmentFinder/config/fieldMappers"

import BrandsIcon from "assets/images/share/brands.svg"
import BrandIcon from "assets/images/share/brand.svg"
import TopBrandIcon from "assets/images/share/top-brand.svg"
import GalleryImage from "assets/images/share/gallery.svg"
import IllustrationImage from "assets/images/share/illustration.svg"

export default function Share() {
  const location = useLocation()
  const apartments = useStore(state => state.apartments)

  const selectedApartments = useMemo(() => {
    const result = []
    const plots = getQueryString(location.search, "units")?.split(",") || []
    for (let i = 0; i < plots.length; i++) {
      const p = plots[i]
      const apartment = apartments.find(a => a[APARTMENT_FIELDS.unitCode] === p)
      if (apartment) {
        result.push(apartment)
      }
    }
    return result
  }, [location, apartments])

  return (
    <Suspense
      fallback={
        <LoaderHolder>
          <Loader />
        </LoaderHolder>
      }>
      <Holder>
        <MiddleContainer>
          <Header>
            <h1>your favorites</h1>
            <img src={TopBrandIcon} alt="" />
          </Header>
          <ApartCardList>
            {selectedApartments?.map((apt, index) => (
              <ApartmentCard key={index} apartment={apt} isShare={false} />
            ))}
          </ApartCardList>
          <Gallery src={GalleryImage} alt="" />
          <Illustration src={IllustrationImage} alt="" />
          <Footer>
            <div>
              <a href="https://park151.com/">PARK151.COM</a>
            </div>
            <div>
              <img src={BrandIcon} alt="" />
            </div>
            <div>
              <img src={BrandsIcon} alt="" />
            </div>
          </Footer>
        </MiddleContainer>
      </Holder>
    </Suspense>
  )
}

const Holder = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fafbf5;
  color: white;
  font-family: AbhayaLibre;
`

const MiddleContainer = styled.div`
  position: relative;
  height: 100%;
  min-width: 300px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #bbccb7;
  overflow: hidden;
  overflow-y: auto;
  @media ${device.pad} {
    width: 100%;
  }
`

const LoaderHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f16449;
  padding: 1.5em;
  h1 {
    color: #f16449;
    text-transform: uppercase;
    font-family: Atlas-Grotesk-Regular;
    font-size: 3.5em;
    letter-spacing: 0.1em;
    margin: 0;
    padding: 0;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
    @media ${device.pad} {
      font-size: 6vw;
    }
  }
  img {
    width: 4em;
    @media ${device.pad} {
      width: 6vw;
    }
  }
  @media ${device.pad} {
    padding: 0.5em;
  }
`

export const ApartCardList = styled.div`
  width: 100%;
  /* height: 900px; */
  display: flex;
  flex-wrap: wrap;
`

export const Gallery = styled.img`
  width: 100%;
`
export const Illustration = styled.img`
  width: 100%;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafbf5;
  padding: 1.5em;
  gap: 1em;
  div {
    flex: 1;
    a {
      font-family: Atlas-Grotesk-Regular;
      color: black;
      text-decoration: none;
    }
    img {
      width: 90%;
    }
  }
  @media ${device.pad} {
    padding: 0.5em;
  }
`
