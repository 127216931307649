export const colours = {
  primary: {
    cGreen: "#BACCB5",
    midnight: "#221F1F",
    cumulus: "#fff"
  },
  secondary: {
    grass: "#4A5417",
    sunset: "#F87144"
  }
}

export const backgroundColours = [
  colours.primary.cGreen,
  colours.secondary.sunset,
  colours.primary.cGreen,
  colours.secondary.sunset,
  colours.primary.cGreen,
  colours.secondary.sunset
]
export const roomColours = {
  0: "#D0F4C7",
  1: "#4A5417",
  2: "#D79E7C",
  3: "#EF774D"
}
