import BedIcon from "assets/icons/bed.svg"
import FloorIcon from "assets/icons/floor.svg"
import PriceIcon from "assets/icons/price.svg"
import AspectIcon from "assets/icons/aspect.svg"
import RefreshIcon from "assets/icons/icon_refresh.svg"
import { useStore } from "store"
import { priceFormatter } from "utils/convertDataFormat"
import { useCallback, useState, useEffect } from "react"
import { Slider } from "primereact/slider"

import styled from "styled-components"

const largeFlex = 1

function ToggleBeds() {
  return <ButtonFilter filterItem={filteringIndex.beds} />
}
function ToggleAspects() {
  return <ButtonFilter filterItem={filteringIndex.aspect} />
}
function SetLevel() {
  return <RangeSlider filterItem={filteringIndex.level} />
}

function SetPrice() {
  return <RangeSlider filterItem={filteringIndex.price} />
}

export const filteringIndex = {
  beds: {
    component: <ToggleBeds />,
    icon: BedIcon,
    action: "toggleBeds",
    flex: largeFlex,
    type: "buttons",
    value: "bedroomCounts",
    criteria: "setBeds",
    buttons: ["STUDIO", "ONE", "TWO", "THREE"],
    delay: 0
  },

  level: {
    component: <SetLevel />,
    icon: FloorIcon,
    action: "setLevel",
    flex: largeFlex,
    type: "range",
    value: "level",
    criteria: "setLevel",
    step: 1,
    delay: 100
  },
  aspect: {
    component: <ToggleAspects />,
    icon: AspectIcon,
    action: "toggleAspect",
    flex: largeFlex,
    type: "buttons",
    value: "aspects",
    criteria: "toggleAspects",
    buttons: ["North", "East", "South", "West"],
    values: ["North", "East", "South", "West"],
    delay: 50
  },
  price: {
    component: <SetPrice />,
    icon: PriceIcon,
    action: "setPrice",
    flex: largeFlex,
    type: "range",
    value: "priceRange",
    criteria: "setPriceRange",
    step: 50,
    delay: 150
  },
  clear: {
    // component: <Clear />,
    icon: RefreshIcon,
    action: "clear",
    flex: 1,
    type: "buttons",
    value: "clear",
    delay: 200
  }
}

function RangeSlider({ filterItem, type }) {
  const [localState, set] = useState([])
  const minPrice = useStore(state => state.minPrice)
  const maxPrice = useStore(state => state.maxPrice)
  const priceRange = useStore(state => state.priceRange)
  const setPriceRange = useStore(state => state.setPriceRange)

  const level = useStore(state => state.level)
  const setLevelRange = useStore(state => state.setLevelRange)
  const minLevel = useStore(state => state.minLevel)
  const maxLevel = useStore(state => state.maxLevel)

  let thisMin
  let thisMax

  useEffect(() => {
    if (filterItem.value === "priceRange") {
      set(priceRange)
    } else if (filterItem.value === "level") {
      set(level)
    }
  }, [priceRange, level, set, filterItem.value])

  if (filterItem.value === "priceRange") {
    thisMin = minPrice
    thisMax = maxPrice
  } else if (filterItem.value === "level") {
    thisMin = minLevel
    thisMax = maxLevel
  }

  const handleSlideEnd = e => {
    if (filterItem.value === "priceRange") {
      setPriceRange(e.value)
    } else if (filterItem.value === "level") {
      setLevelRange(e.value)
    }
  }

  const handleRangeChange = useCallback(
    e => {
      if (filterItem.value === "priceRange") {
        set(e.value)
      } else if (filterItem.value === "level") {
        set(e.value)
      }
    },
    [setPriceRange, filterItem.value, setLevelRange]
  )

  return (
    <PriceSlider
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
      }}>
      <p>
        {filterItem.value === "priceRange"
          ? priceFormatter.format(localState[0])
          : localState[0]}
      </p>

      <Slider
        range
        value={localState}
        onChange={e => handleRangeChange(e)}
        step={filterItem.step}
        min={thisMin}
        max={thisMax}
        // orientation="vertical"
        onSlideEnd={e => handleSlideEnd(e)}
        style={{ width: "100%" }}
      />
      <p>
        {filterItem.value === "priceRange"
          ? priceFormatter.format(localState[1])
          : localState[1]}
      </p>
    </PriceSlider>
  )
}

const PriceSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  gap: 1em;
  p {
    font-size: 0.5em;
    letter-spacing: 0.2em;
    color: #202020;
    width: 3em;
  }
`

function ButtonFilter({ filterItem }) {
  const toggleBedroomCounts = useStore(state => state.toggleBedroomCounts)
  const bedroomCounts = useStore(state => state.bedroomCounts)
  const aspects = useStore(state => state.aspects)
  const toggleAspects = useStore(state => state.toggleAspects)

  const handleButtonPress = useCallback(
    (event, v) => {
      console.log(v)
      event.stopPropagation()
      event.preventDefault()
      if (filterItem.value === "bedroomCounts") {
        toggleBedroomCounts(v)
      } else if (filterItem.value === "aspects") {
        toggleAspects(v)
      }
    },
    [filterItem.value, toggleAspects, toggleBedroomCounts]
  )

  return (
    <ButtonContainer>
      {filterItem.buttons.map((v, i) => (
        <FilterButton
          fill={
            bedroomCounts.includes(String(i)) ||
            aspects.includes(
              v === "South" || v === "North" || v === "East" || v === "West"
                ? filterItem?.values[i]
                : String(i)
            )
              ? "underline"
              : "none"
          }
          key={i}
          onClick={e =>
            //  handleButtonPress(e, v)
            handleButtonPress(
              e,
              v === "South" || v === "North" || v === "East" || v === "West"
                ? v
                : String(i)
            )
          }>
          {v}
        </FilterButton>
      ))}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  flex-shrink: 1;
  justify-content: space-around;

  align-items: center;
`

const FilterButton = styled.div`
  cursor: pointer;
  font-family: "Circular-Std";
  flex: 1;
  /* fill: ${props => props.fill}; */
  text-decoration: ${props => props.fill};
  /* text-decoration-color: #f87144cc; */
  /* border: ${props => props.fill} solid 1px; */
  padding: 10px 5px;
  border-radius: 3rem;
  font-size: 1.5rem;
  width: fit-content;
  display: flex;
  letter-spacing: 0;
  margin: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #030201;
`
